import { IRootState } from "../reducers/root";

export function selectUserFullname(state: IRootState, actual?: boolean): string {
    if (!actual) {
        return (
            state.session.userList.users.find(user => user.ixs_ref === state.session.userList.selectedIxsRef)
                ?.fullname ?? ""
        );
    }

    const token = state.session.user.token;

    const firstname = token?.givenName ?? "";
    const lastname = token?.surname ?? "";
    const degBef = token?.degreeBefore ?? "";
    const degAft = token?.degreeAfter ?? "";
    const pn = token?.personalNumber ?? "";
    const order = token?.orderOfEmployment ?? "";

    return `${lastname} ${firstname} ${degBef} ${degAft} ${pn} (${order})`.replace(/ {2,}/, " ").replace("()", "");
}
