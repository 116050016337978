import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";
import { DelegatedAuthStyle } from "../../model/app/DelegatedAuthStyle";

interface StartupResult {
    isAppEnabled: boolean;
    canRunWindowsAuth: boolean;
    isDelegatedAuthEnabled: boolean;
    delegatedAuthStyle: DelegatedAuthStyle;
    delegatedAuthCustomizableText: string;
    secondaryCustomizableText: string;
    multiTenant: boolean;
    substitutionsEnabled: boolean;
}

const reloadTried = "RELOAD_TRIED_STARTUP";

export const fetchStartup = createAsyncThunk<Partial<StartupResult>, void, { state: IRootState }>(
    "app/startup/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            signal: api.signal,
        };

        let response: Response | undefined;

        try {
            response = await fetch(
                api.getState().config.config.endpoint + "/controllers/SelfService/Startup",
                requestOptions
            );
            sessionStorage.removeItem(reloadTried);
        } catch (error) {
            console.error(error);
            if (!sessionStorage.getItem(reloadTried)) {
                sessionStorage.setItem(reloadTried, "1");
                document.body.innerHTML = "";
                window.location.reload();
            } else {
                sessionStorage.removeItem(reloadTried);
                throw error;
            }
        }

        if (response) {
            await handleResponseError(response);

            return (await response.json()) as Partial<StartupResult>;
        }

        return {};
    }
);

export const resetNotification = createAction("app/notification/reset");

export const setCustomerCode = createAction<string>("app/setCustomerCode");

export const checkLicense = createAsyncThunk<string, void, { state: IRootState }>("app/license", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${api.getState().session.user.token?.access_token}`,
        },
    };

    const response = await fetch(
        api.getState().config.config.endpoint + "/breeze/IpoData/IsSelfServiceLicenseValid",
        requestOptions
    );

    await handleResponseError(response);

    return await response.json();
});
