import React from "react";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { NavBar } from "../../../components/NavBar/NavBar";
import styles from "../Requests.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox } from "@progress/kendo-react-inputs";
import { RouteChildrenProps } from "react-router-dom";
import RequestDetail from "../RequestDetail";
import NewRequestDialog from "./../../../components/NewRequestDialog/NewRequestDialog";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import { IRootState } from "../../../redux/reducers/root";
import { fetchMyRequestsDatasource } from "../../../redux/actions/myRequests";
import { IStrings } from "../../../constants/languageStrings/IStrings";
import { IBaseMenuItem } from "../../../components/NavBar/IBaseMenuItem";
import { WflReq } from "../../../model/wflReq";
import PermissionGuard from "../../../components/PermissionGuard";
import { permissions } from "../../../constants/permissions";
import { SpinnerBox } from "../../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../../components/ErrorNotification";
import { SerializedError } from "@reduxjs/toolkit";
import { remainingDaysString } from "../../../utils/LocalizationUtils";

export class MyRequests extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        //TODO: fix potential error throw when getItem() is called
        let isChecked: boolean =
            localStorage.getItem(this.props.isRequest ? "isCheckedMyRequests" : "isCheckedUnavailabilities") === "true";
        this.state = {
            onlyPending: isChecked,
            dialog: {
                detail: false,
                newRequest: false,
            },
        };
    }

    async componentDidMount() {
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch({ BoolValue: this.state.onlyPending, IsRequests: this.props.isRequest });
    }

    openDetailDialog(id: number) {
        if (this.props.dataSource !== undefined) {
            let data = this.props.dataSource.find(ipo => ipo.ID === id);
            this.setState({ detailData: data });
            this.setState({ dialog: { detail: true } });
        }
    }

    async openNewRequestDialog() {
        this.setState({ dialog: { newRequest: true } });
    }

    handleOnlyPendingChange = () => {
        let result: boolean = !this.state.onlyPending;
        localStorage.setItem(
            this.props.isRequest ? "isCheckedMyRequests" : "isCheckedUnavailabilities",
            result.toString()
        );
        this.setState({ onlyPending: result });
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch({ BoolValue: result, IsRequests: this.props.isRequest });
    };

    private handleDetailRequestDialogClose = () => {
        this.setState({ dialog: { detail: false } });
        this.setState({ dialog: { newRequest: false } });
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch({
                BoolValue: this.state.onlyPending,
                IsRequests: this.props.isRequest,
            });
    };

    private createMenuItems = () => {
        const strings = this.props.strings;
        return [
            {
                id: 1,
                content: (
                    <Checkbox key={0} id={"checkForWaiting"} checked={this.state.onlyPending}>
                        <label htmlFor={"checkForWaiting"} className={"k-checkbox-label"}>
                            {strings.requests.ShowOnly}
                        </label>
                    </Checkbox>
                ),
            },
        ] as IBaseMenuItem[];
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                this.handleOnlyPendingChange();
                break;
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    private getRemainingDays(RemainingDays: number, State: number): React.ReactNode {
        const strings = this.props.strings;
        if (State === 0) {
            return remainingDaysString(strings, RemainingDays);
        }
    }

    private getAccountText(Text: string): React.ReactNode {
        if (this.props.isRequest) {
            let separator: string = Text.indexOf(".") === -1 ? "/" : ".";
            return Text.substr(0, Text.indexOf(separator) - 2);
        } else {
            return Text.substr(Text.lastIndexOf(":") + 4);
        }
    }

    private getFromTo(Text: string): React.ReactNode {
        let separator: string = Text.indexOf(".") === -1 ? "/" : ".";
        if (this.props.isRequest) {
            return Text.substr(Text.indexOf(separator) - 2);
        } else {
            return Text.substring(Text.indexOf(separator) - 2, Text.lastIndexOf(":") + 4);
        }
    }

    render() {
        let rows: WflReq[] = this.props.dataSource || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={
                            this.props.isRequest
                                ? this.props.strings.dashboard.MyRequests
                                : this.props.strings.unavailability.Unavailability
                        }
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <PermissionGuard permission={permissions.plan.absence}>
                            <Button onClick={() => this.openNewRequestDialog()}>
                                {this.props.strings.requests.NewReq}
                            </Button>
                        </PermissionGuard>
                    </NavBar>
                }
            >
                {this.props.isDatasourceFetching ? (
                    <SpinnerBox></SpinnerBox>
                ) : (
                    <>
                        {this.props.error ? (
                            <ErrorNotification error={this.props.error} />
                        ) : (
                            <List>
                                {rows.length === 0 && (
                                    <label className={styles.noData}>{this.props.strings.requests.NoData}</label>
                                )}
                                {/* řádky */}
                                {rows.map((row: WflReq, key: any) => (
                                    <ListItem
                                        key={key}
                                        divider
                                        dense
                                        button
                                        onClick={() => this.openDetailDialog(row.ID)}
                                    >
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <span className={styles.accountName}>
                                                        {this.getAccountText(row.ReqText)}
                                                    </span>
                                                    <br></br>
                                                    {/* {localization.strings.requests.From + " "}{row.from}{" "+localization.strings.requests.To + " "}{row.to} */}
                                                    {this.getFromTo(row.ReqText)}
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    {this.getRemainingDays(row.RemainingDaysFloor, row.State)}
                                                </React.Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <div className={styles.icon}>
                                                {row.State === 0 && (
                                                    <span
                                                        style={{ color: "blue" }}
                                                        className={"k-icon k-i-clock"}
                                                    ></span>
                                                )}
                                                {row.State === 1 && (
                                                    <span
                                                        style={{ color: "green" }}
                                                        className={"k-icon k-i-check"}
                                                    ></span>
                                                )}
                                                {row.State === 2 && (
                                                    <span
                                                        style={{ color: "red" }}
                                                        className={"k-icon k-i-close"}
                                                    ></span>
                                                )}
                                                {row.State === 3 && (
                                                    <span className={"k-icon k-i-cancel-outline"}></span>
                                                )}
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </>
                )}

                {this.state.dialog.detail && (
                    <RequestDetail
                        data={this.state.detailData!}
                        onClose={this.handleDetailRequestDialogClose}
                        isApprover={false}
                    />
                )}
                {this.state.dialog.newRequest && (
                    <NewRequestDialog
                        isRequest={this.props.isRequest}
                        onlyWorkflow={true}
                        onClose={this.handleDetailRequestDialogClose}
                        canSelectPerson={false}
                    />
                )}
            </PageLayout>
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,

        isDatasourceFetching: state.session.myRequests?.isFetching,
        dataSource: state.session.myRequests?.datasource,
        error: state.session.myRequests?.error,
    }),
    {
        onDatasourceFetch: e => fetchMyRequestsDatasource(e),
    }
)(MyRequests);

interface IOwnProps {
    isRequest: boolean;
}

interface IStateProps {
    strings: IStrings;

    isDatasourceFetching?: boolean;
    dataSource?: WflReq[];
    error?: SerializedError;
}

interface IDispatchProps {
    onDatasourceFetch: (e: { BoolValue: boolean; IsRequests: boolean }) => void;
}

interface IState {
    onlyPending: boolean;
    detailData?: WflReq;
    dialog: {
        detail?: boolean;
        newRequest?: boolean;
    };
}

type IProps = IStateProps & IDispatchProps & RouteChildrenProps & IOwnProps;
