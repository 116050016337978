import { SerializedError, createReducer } from "@reduxjs/toolkit";
import { IUserListItem } from "../../model/IUserList";
import { fetchUserList /*, setSelectedUser*/ } from "../actions/userList";
import { fetchToken } from "../actions/user";

export interface IUserListState {
    users: IUserListItem[];
    selectedIxsRef?: string;
    error?: SerializedError;
}

export const userListReducer = createReducer<IUserListState>(
    {
        users: [],
    },
    builder =>
        builder
            .addCase(fetchUserList.pending, (state, action) => ({
                ...state,
                error: undefined,
            }))
            .addCase(fetchUserList.fulfilled, (state, action) => ({
                ...state,
                users: action.payload.users ?? [],
                error: undefined,
            }))
            .addCase(fetchUserList.rejected, (state, action) => ({
                ...state,
                error: action.error,
            }))
            .addCase(fetchToken.fulfilled, (state, action) => ({
                ...state,
                selectedIxsRef: action.payload.sidSubst ? action.payload.sidSubst : action.payload.sid,
            }))
);
