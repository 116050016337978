import React, { useEffect, useRef, useState } from "react";
import { IColumnConfig, IGroup } from "../../../components/Timeline/types";

const SECONDS_PER_PERCENT = 100 / (24 * 60 * 60);

export default function NowGroupMarker<G extends IGroup>(props: { column: IColumnConfig; group: G }) {
    const intervalID = useRef<NodeJS.Timer>();

    const [now, setNow] = useState(new Date());

    useEffect(() => {
        if (props.column.beginDay.getTime() > props.column.endDay.getTime()) {
            throw new Error("Begin time have to be lower then end time.");
        }

        intervalID.current = setInterval(() => {
            setNow(new Date());
        }, 10000);

        return () => intervalID.current && clearInterval(intervalID.current);
    }, [props]);

    if (
        now < props.column.beginDay ||
        now > props.column.endDay ||
        new Date(props.group.id).getDate() !== now.getDate()
    ) {
        return null;
    }

    const actual = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

    const percent = SECONDS_PER_PERCENT * actual;

    return (
        <div
            style={{
                height: "100%",
                width: "1px",
                borderLeft: "1px solid red",
                marginLeft: `${percent}%`,
            }}
        ></div>
    );
}
