import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
    fetchAwData,
    fetchAwShifts,
    resetAwData,
    scrollAwNext,
    scrollAwPrevious,
} from "../../redux/actions/agreementWorkers";
import AwCenterDialog from "./AwCenterDialog";
import AwNotificationDialog from "./AwNotificationDialog";
import AwTimeDialog from "./AwTimeDialog";
import { useStrings } from "../../hooks/useStrings";
import FreeShiftGrid from "./FreeShiftGrid";
import { useAppSelector } from "../../hooks/useAppSelector";
import NavbarMonthPicker from "../../components/NavbarMonthPicker/NavbarMonthPicker";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { AwShift } from "../../redux/reducers/agreementWorkers";
import AwSelectionDialog from "./AwSelectionDialog";
import { initialize as initializeCenterPicker } from "../../redux/actions/centerPicker";
import AwCenterPicker from "./AwCenterPicker";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export default function FreeShifts() {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const [centersDialogVisible, setCentersDialogVisible] = useState(false);
    const [notificationsDialogVisible, setNotificationsDialogVisible] = useState(false);
    const [timesDialogVisible, setTimesDialogVisible] = useState(false);
    const [month, setMonth] = useState(new Date());
    const centers = useAppSelector(s => s.session.freeShifts.centers);
    const [selectedShift, setSelectedShift] = useState<AwShift | undefined>();
    const selectedCenter = useAppSelector(s => s.session.centerPicker.selected);
    const [centerPickerVisible, setCenterPickerVisible] = useState(false);

    const data = useAppSelector(s => s.session.freeShifts.data);
    const shouldFetch = useAppSelector(s => s.session.freeShifts.shouldFetch);
    const [index, setIndex] = useState(0);

    const menuItems: IBaseMenuItem[] = [
        { id: 1, content: strings.freeShifts.CenterSelection },
        { id: 2, content: strings.freeShifts.CentersConfig },
        { id: 3, content: strings.freeShifts.TimesConfig },
        { id: 4, content: strings.freeShifts.NotifConfig },
    ];

    const onMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                setCenterPickerVisible(true);
                break;
            case 2:
                setCentersDialogVisible(true);
                break;
            case 3:
                setTimesDialogVisible(true);
                break;
            case 4:
                setNotificationsDialogVisible(true);
                break;
            default:
                item.id && console.warn("Unhandled value:", item.id);
        }
    };

    useEffect(() => {
        dispatch(initializeCenterPicker());
        dispatch(fetchAwData());
        return () => {
            dispatch(resetAwData());
        };
    }, [dispatch]);

    useEffect(() => {
        if (selectedCenter !== null) {
            if (shouldFetch[1]) {
                dispatch(fetchAwShifts({ month: month, pageIndex: 1 }));
            } else if (shouldFetch[2] && data[1]) {
                dispatch(fetchAwShifts({ month: month.addMonths(1), pageIndex: 2 }));
            } else if (shouldFetch[0] && data[1] && data[2]) {
                dispatch(fetchAwShifts({ month: month.addMonths(-1), pageIndex: 0 }));
            }
        }
    }, [dispatch, centers, month, shouldFetch, selectedCenter, data]);

    const scrollPrevious = () => {
        dispatch(scrollAwPrevious());
        const newMonth = month.addMonths(-1);
        setMonth(newMonth);
    };

    const scrollNext = () => {
        dispatch(scrollAwNext());
        const newMonth = month.addMonths(1);
        setMonth(newMonth);
    };

    const setNavbarMonth = (newMonth: Date) => {
        if (month.getTime() !== newMonth.getTime()) {
            dispatch(resetAwData());
            setMonth(newMonth);
        }
    };

    return (
        <>
            {centersDialogVisible && (
                <AwCenterDialog
                    onClose={refresh => {
                        setCentersDialogVisible(false);
                        refresh && dispatch(resetAwData());
                    }}
                />
            )}
            {notificationsDialogVisible && (
                <AwNotificationDialog onClose={() => setNotificationsDialogVisible(false)} />
            )}
            {timesDialogVisible && (
                <AwTimeDialog
                    onClose={refresh => {
                        setTimesDialogVisible(false);
                        refresh && dispatch(resetAwData());
                    }}
                />
            )}
            <PageLayout
                canScrollPrevious={!!data[0]}
                canScrollNext={!!data[2]}
                onScrollPrevious={scrollPrevious}
                onScrollNext={scrollNext}
                header={
                    <NavBar
                        canNavigateRoot
                        menu={{ items: menuItems, onItemClick: onMenuItemClick }}
                        label={strings.dashboard.FreeShifts}
                    >
                        <NavbarMonthPicker selectedMonth={month} onPick={setNavbarMonth} />
                    </NavBar>
                }
            >
                <VirtualizeSwipeableViews
                    style={{ minHeight: "100%" }}
                    overscanSlideBefore={1}
                    overscanSlideAfter={1}
                    slideRenderer={e => (
                        <FreeShiftGrid
                            onSelect={setSelectedShift}
                            key={e.index}
                            month={month.addMonths(e.index - index)}
                            shifts={data[e.index - index + 1]?.shifts ?? []}
                            categories={data[e.index - index + 1]?.categories ?? []}
                            loading={data[e.index - index + 1] === undefined}
                        />
                    )}
                    onChangeIndex={(index1, index0) => {
                        setIndex(index1);
                        if (index0 < index1) {
                            scrollNext();
                        } else {
                            scrollPrevious();
                        }
                    }}
                />

                {selectedShift && (
                    <AwSelectionDialog onClose={() => setSelectedShift(undefined)} shift={selectedShift} />
                )}

                {centerPickerVisible && <AwCenterPicker onClose={() => setCenterPickerVisible(false)} />}
            </PageLayout>
        </>
    );
}
