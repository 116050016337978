import { createReducer } from "@reduxjs/toolkit";
import { fetchAccounts } from "../../actions/dropdowns/accounts";

export interface IAccountsState {
    isFetching: boolean;
    accounts?: IAccount[];
    specifications?: ISpecification[];
    error?: unknown;
}

export interface IAccount {
    id: number;
    value: string;
    abs: string;
    strav: number;
    workflow: number;
}

export interface ISpecification {
    strav: number;
    nazev: string;
}

export const accountsReducer = createReducer<IAccountsState>(
    {
        isFetching: false,
        accounts: undefined,
        specifications: undefined,
    },
    builder => {
        builder
            .addCase(fetchAccounts.pending, (state, action) => ({
                isFetching: true,
                accounts: undefined,
                specifications: undefined,
                error: undefined,
            }))
            .addCase(fetchAccounts.fulfilled, (state, action) => ({
                isFetching: false,
                accounts: action.payload.accounts,
                specifications: action.payload.specifications,
                error: undefined,
            }))
            .addCase(fetchAccounts.rejected, (state, action) => ({
                isFetching: false,
                accounts: [],
                specifications: [],
                error: action.payload,
            }));
    }
);
