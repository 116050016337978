import React, { ReactNode } from "react";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { NavBar } from "../../../components/NavBar/NavBar";
import styles from "../Requests.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { RouteChildrenProps, RouteComponentProps } from "react-router-dom";
import RequestDetail from "../RequestDetail";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import { IRootState } from "../../../redux/reducers/root";
import { IStrings } from "../../../constants/languageStrings/IStrings";
import { fetchRequestsForApprovalDatasource } from "../../../redux/actions/requestsForApproval";
import { IBaseMenuItem } from "../../../components/NavBar/IBaseMenuItem";
import { WflReq } from "../../../model/wflReq";
import NewRequestDialog from "../../../components/NewRequestDialog/NewRequestDialog";
import { SerializedError } from "@reduxjs/toolkit";
import { SpinnerBox } from "../../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../../components/ErrorNotification";
import { ListItemIcon } from "@material-ui/core";
import { wflChangeStateMultiple } from "../../../redux/actions/wflStateChange";
import { permissions } from "../../../constants/permissions";
import { QueryParam2 } from "../../../model/CommonQueryParams";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { remainingDaysString } from "../../../utils/LocalizationUtils";

export class RequestsForApproval extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        let isChecked: boolean = localStorage.getItem("isCheckedApprovalRequests") === "true";
        this.state = {
            onlyPending: isChecked,
            searchText: "",
            dialog: {
                detail: false,
                newRequest: false,
            },
            selectionMode: false,
            checkedReqs: [],
            multiDialogVisible: false,
            multiStatus: 0,
        };
    }

    async componentDidMount() {
        (await this.props.onDatasourceFetch) &&
            this.props.onDatasourceFetch([this.state.searchText, this.state.onlyPending, this.props.isRequest]);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const reqId = Number(this.props.match?.params.id);
        if (reqId > 0) {
            this.openDetailDialog(reqId);
        }
    }

    openDetailDialog(id: number) {
        if (this.props.dataSource !== undefined) {
            let data = this.props.dataSource.find(ipo => ipo.ID === id);
            if (data !== undefined) {
                this.setState({ detailData: data });
                this.setState({ dialog: { detail: true } });
                this.props.history.push(this.props.isRequest ? "/requests-approve" : "/unavailabilities-approve");
            }
        }
    }

    async openNewRequestDialog() {
        this.setState({ dialog: { newRequest: true } });
    }

    handleOnlyPendingChange = () => {
        let result: boolean = !this.state.onlyPending;
        localStorage.setItem("isCheckedApprovalRequests", result.toString());
        this.setState({ onlyPending: result, checkedReqs: [], selectionMode: false });
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch([this.state.searchText, result, this.props.isRequest]);
    };

    private handleDetailRequestDialogClose = () => {
        this.setState({ dialog: { detail: false } });
        this.setState({ dialog: { newRequest: false } });
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch([this.state.searchText, this.state.onlyPending, this.props.isRequest]);
    };

    private createMenuItems = () => {
        const strings = this.props.strings;
        var items = [
            {
                id: 1,
                content: (
                    <Checkbox key={0} id={"checkForWaiting"} checked={this.state.onlyPending}>
                        <label htmlFor={"checkForWaiting"} className={"k-checkbox-label"}>
                            {strings.requests.ShowOnly}
                        </label>
                    </Checkbox>
                ) as ReactNode,
            },
        ];
        if (this.props.isAbsenceApprover === undefined || this.props.isAbsenceApprover || !this.props.isRequest) {
            items.push({ id: 2, content: this.props.strings.requests.NewRequestFor });
        }
        if (!this.state.selectionMode) {
            items.push({
                id: 3,
                content: this.props.strings.requests.MassApprove,
            });
        }
        return items as IBaseMenuItem[];
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 2:
                this.openNewRequestDialog();
                break;
            case 1:
                this.handleOnlyPendingChange();
                break;
            case 3:
                this.setState({ selectionMode: !this.state.selectionMode, checkedReqs: [] });
                break;
            default:
                item.id && console.warn("Unhandled value: " + item.id);
        }
    };

    private getRemainingDays(RemainingDays: number, State: number): React.ReactNode {
        const strings = this.props.strings;
        if (State === 0) {
            return remainingDaysString(strings, RemainingDays);
        }
    }

    private getAccountText(Text: string): React.ReactNode {
        if (this.props.isRequest) {
            let separator: string = Text.indexOf(".") === -1 ? "/" : ".";
            return Text.substr(0, Text.indexOf(separator) - 2);
        } else {
            return Text.substr(Text.lastIndexOf(":") + 4);
        }
    }

    private getFromTo(Text: string): React.ReactNode {
        let separator: string = Text.indexOf(".") === -1 ? "/" : ".";
        if (this.props.isRequest) {
            return Text.substr(Text.indexOf(separator) - 2);
        } else {
            return Text.substring(Text.indexOf(separator) - 2, Text.lastIndexOf(":") + 4);
        }
    }

    private searchData(): void {
        this.props.onDatasourceFetch &&
            this.props.onDatasourceFetch([this.state.searchText, this.state.onlyPending, this.props.isRequest]);
    }

    private isEverythingselected = () =>
        this.state.checkedReqs.length === this.props.dataSource?.filter(f => f.State === 0).length;

    private isNothingSelected = () => this.state.checkedReqs.length === 0;

    private toggleSelection(req?: WflReq): void {
        if (req) {
            if (req.State === 0) {
                if (this.state.checkedReqs.some(s => s === req.ID)) {
                    this.setState({ checkedReqs: this.state.checkedReqs.filter(f => f !== req.ID) });
                } else {
                    this.setState({ checkedReqs: this.state.checkedReqs.concat([req.ID]) });
                }
            }
        } else {
            if (this.isEverythingselected()) {
                this.setState({ checkedReqs: [] });
            } else {
                this.setState({ checkedReqs: this.props.dataSource?.filter(f => f.State === 0).map(m => m.ID) ?? [] });
            }
        }
    }

    private getChechboxIconClass() {
        return this.state.checkedReqs.length === 0
            ? "k-icon k-i-checkbox"
            : this.isEverythingselected()
            ? "k-icon k-i-checkbox-checked"
            : "k-icon k-i-tri-state-indeterminate";
    }

    render() {
        let rows: WflReq[] = this.props.dataSource || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={
                            this.props.isRequest
                                ? this.props.strings.requests.ReqForApproval
                                : this.props.strings.unavailability.Unavailabilities
                        }
                        menu={{ items: this.createMenuItems(), onItemClick: this.handleMenuItemClick }}
                    >
                        <Button
                            className={this.state.selectionMode ? styles.navBarElementIn : styles.navBarElementOut}
                            iconClass={"k-icon k-i-check"}
                            onClick={() => this.setState({ multiDialogVisible: true, multiStatus: 1 })}
                            disabled={this.isNothingSelected()}
                        />
                        {this.props.reqNotePerm && (
                            <Button
                                className={this.state.selectionMode ? styles.navBarElementIn : styles.navBarElementOut}
                                iconClass={"k-icon k-i-x"}
                                onClick={() => this.setState({ multiDialogVisible: true, multiStatus: 2 })}
                                disabled={this.isNothingSelected()}
                            />
                        )}
                        <Input
                            className={
                                this.state.selectionMode
                                    ? styles.searchBar + " " + styles.navBarElementOut
                                    : styles.searchBar + " " + styles.navBarElementIn
                            }
                            placeholder={this.props.strings.requests.Search}
                            value={this.state.searchText}
                            onChange={e => this.setState({ searchText: e.value })}
                        />
                        <Button
                            className={this.state.selectionMode ? styles.navBarElementOut : styles.navBarElementIn}
                            iconClass={"k-icon k-i-search"}
                            onClick={() => this.searchData()}
                        />
                    </NavBar>
                }
                footer={
                    <div className={this.state.selectionMode ? styles.footerIn : styles.footerOut}>
                        <div className={styles.footerCount}>
                            <span>
                                {this.state.checkedReqs.length +
                                    "/" +
                                    this.props.dataSource?.filter(f => f.State === 0).length}
                            </span>
                        </div>
                        <div style={{ display: "flex", padding: "0.5rem" }}>
                            <Button
                                look="flat"
                                iconClass={this.getChechboxIconClass()}
                                onClick={() => this.toggleSelection()}
                            >
                                {this.props.strings.requests.SelectAll}
                            </Button>
                            <div style={{ flexGrow: 1 }} />
                            <Button look="flat" onClick={() => this.setState({ selectionMode: false })}>
                                {this.props.strings.common.Cancel}
                            </Button>
                        </div>
                    </div>
                }
            >
                {(this.props.isDatasourceFetching || this.props.changePending) && <SpinnerBox></SpinnerBox>}
                {this.props.error && <ErrorNotification error={this.props.error}></ErrorNotification>}
                {!this.props.isDatasourceFetching && !this.props.changePending && !this.props.error && (
                    <>
                        {rows.length === 0 && (
                            <label className={styles.noData}>{this.props.strings.requests.NoData}</label>
                        )}
                        <List>
                            {/* řádky */}
                            {rows.map((row: WflReq, key: any) => (
                                <ListItem
                                    key={key}
                                    divider
                                    dense
                                    button
                                    disableGutters
                                    onClick={() => {
                                        if (this.state.selectionMode) this.toggleSelection(row);
                                        else this.openDetailDialog(row.ID);
                                    }}
                                    onContextMenu={e => {
                                        e.preventDefault();
                                        if (this.state.selectionMode) this.toggleSelection(row);
                                        else if (row.State === 0)
                                            this.setState({ selectionMode: true, checkedReqs: [row.ID] });
                                        else this.setState({ selectionMode: true, checkedReqs: [] });
                                    }}
                                >
                                    <ListItemIcon
                                        className={
                                            this.state.selectionMode
                                                ? row.State === 0
                                                    ? styles.checkboxIn
                                                    : styles.checkboxHidden
                                                : styles.checkboxOut
                                        }
                                    >
                                        <Checkbox
                                            className={styles.checkbox}
                                            checked={this.state.checkedReqs.some(s => s === row.ID)}
                                        ></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <div>
                                                <div className={"k-row"}>
                                                    <span className={styles.accountName}>
                                                        {row.Applicant + " - " + this.getAccountText(row.ReqText)}
                                                    </span>
                                                </div>
                                                <div className={"k-row"}>
                                                    <span className={styles.noWrapText}>
                                                        {this.getFromTo(row.ReqText)}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                {this.getRemainingDays(row.RemainingDaysFloor, row.State)}
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <div className={styles.icon}>
                                            {row.State === 0 && (
                                                <span style={{ color: "blue" }} className={"k-icon k-i-clock"}></span>
                                            )}
                                            {row.State === 1 && (
                                                <span style={{ color: "green" }} className={"k-icon k-i-check"}></span>
                                            )}
                                            {row.State === 2 && (
                                                <span style={{ color: "red" }} className={"k-icon k-i-close"}></span>
                                            )}
                                            {row.State === 3 && <span className={"k-icon k-i-cancel-outline"}></span>}
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {this.state.dialog.detail && (
                    <RequestDetail
                        data={this.state.detailData!}
                        onClose={this.handleDetailRequestDialogClose}
                        isApprover={true}
                    />
                )}
                {this.state.multiDialogVisible && (
                    <Dialog
                        closeIcon={false}
                        title={
                            this.state.multiStatus === 1
                                ? this.props.strings.requests.ApproveSelected
                                : this.props.strings.requests.RejectSelected
                        }
                    >
                        <DialogActionsBar>
                            <Button onClick={() => this.setState({ multiDialogVisible: false })}>
                                {this.props.strings.common.Cancel}
                            </Button>
                            <Button
                                autoFocus
                                onClick={() => {
                                    this.setState({ multiDialogVisible: false });
                                    const reqs = this.props.dataSource
                                        ?.filter(f => this.state.checkedReqs.includes(f.ID))
                                        .sort((a, b) => +new Date(a.Expdate) - +new Date(b.Expdate))
                                        .map(m => m.ID);
                                    this.props
                                        .onSetStateMultiple({ Value: reqs, Value2: this.state.multiStatus })
                                        .then(() =>
                                            this.props.onDatasourceFetch([
                                                this.state.searchText,
                                                this.state.onlyPending,
                                                this.props.isRequest,
                                            ])
                                        );
                                    this.setState({ checkedReqs: [], selectionMode: false });
                                }}
                            >
                                {this.props.strings.common.Yes}
                            </Button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.dialog.newRequest && (
                    <NewRequestDialog
                        isRequest={this.props.isRequest}
                        onlyWorkflow={false}
                        onClose={this.handleDetailRequestDialogClose}
                        canSelectPerson={true}
                    />
                )}
            </PageLayout>
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,

        isDatasourceFetching: state.session.requestsForApproval?.isFetching,
        changePending: state.session.wflChangeState.inserting,
        error: state.session.requestsForApproval?.error,
        dataSource: state.session.requestsForApproval?.datasource,
        isAbsenceApprover: state.session.dashboard.data?.isAbsenceApprover,
        reqNotePerm:
            state.session.user.token?.userDisabledActions.some(p => p === permissions.requestsForApproval.rejectNote) ??
            false,
    }),
    {
        onDatasourceFetch: e => fetchRequestsForApprovalDatasource(e),
        onSetStateMultiple: e => wflChangeStateMultiple(e),
    }
)(RequestsForApproval);

interface IOwnProps {
    isRequest: boolean;
}

interface IStateProps {
    strings: IStrings;
    isDatasourceFetching?: boolean;
    changePending?: boolean;
    error?: SerializedError;
    dataSource?: WflReq[];
    isAbsenceApprover?: boolean;
    reqNotePerm: boolean;
}

interface IDispatchProps {
    onDatasourceFetch: (e: [string, boolean, boolean]) => void;
    onSetStateMultiple: (e: QueryParam2<number[], number>) => any;
}

interface IState {
    onlyPending: boolean;
    detailData?: WflReq;
    searchText: string;
    dialog: {
        detail?: boolean;
        newRequest?: boolean;
    };
    selectionMode: boolean;
    checkedReqs: number[];
    multiDialogVisible: boolean;
    multiStatus: number;
}

type IProps = IStateProps & IDispatchProps & RouteChildrenProps & ComponentProps & IOwnProps;

interface RouteInfo {
    id: string;
}
interface ComponentProps extends RouteComponentProps<RouteInfo> {}
