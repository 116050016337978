import React from "react";
import Stack from "../../components/Timeline/stacking/Stack";
import { generateRandomInterval } from "../../components/Timeline/generators";

export function StackTestPage() {
    return (
        <>
            <div>Pro otestování prováděj resize okna a sleduj resize prvku.</div>
            <div style={{ maxWidth: "100%", display: "grid", gridTemplateColumns: "auto 1fr" }}>
                <div>1. Po</div>
                <Stack intervals={[generateRandomInterval(0, 1, "Intr 1"), generateRandomInterval(0, 1, "Intr 2")]} />
            </div>
        </>
    );
}
