import React, { useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useStrings } from "../../hooks/useStrings";
import { DelegatedAuthStyle } from "../../model/app/DelegatedAuthStyle";
import LogInForm from "./LogInForm";
import { redirectToDelegatedAuth } from "../../redux/actions/user";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { AuthErrorRenderer } from "./AuthErrorRenderer";
import CustomerCodeForm from "./CustomerCodeForm";
import { setCustomerCode } from "../../redux/actions/app";
import { LOCALSTORAGE_CUSTOMERCODE } from "../../constants/common";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";

export function AuthLayout() {
    const isDelegatedAuthEnabled = useAppSelector<boolean>(s => s.app.isDelegatedAuthEnabled);
    const style = useAppSelector(s => s.app.delegatedAuthStyle);
    const customizableText = useAppSelector(s => s.app.delegatedAuthCustomizableText);
    const secondaryText = useAppSelector(s => s.app.secondaryCustomizableText);
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const isAuthenticating = useAppSelector<boolean>(s => s.session.user.isAuthenticating);
    const customerCode = useAppSelector(s => s.app.customerCode);
    const multiTenant = useAppSelector(s => s.app.multiTenant);
    const loading = useAppSelector(s => s.session.user.isAuthenticating);
    const [codeValid, setCodeValid] = useState(customerCode !== "");

    if (multiTenant && !codeValid) {
        return loading ? (
            <SpinnerBox />
        ) : (
            <CustomerCodeForm
                code={customerCode}
                onSetCode={code => {
                    localStorage.setItem(LOCALSTORAGE_CUSTOMERCODE, code);
                    dispatch(setCustomerCode(code));
                    setCodeValid(true);
                }}
            />
        );
    }

    if (!isDelegatedAuthEnabled || style === DelegatedAuthStyle.automaticRedirection) {
        return (
            <>
                <LogInForm onCodeClear={() => setCodeValid(false)} />
                {secondaryText}
                <br />
                <AuthErrorRenderer />
            </>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {customizableText}
            <Button
                className={"w-100"}
                primary
                disabled={isAuthenticating}
                icon={isAuthenticating ? "loading" : undefined}
                onClick={() => dispatch(redirectToDelegatedAuth())}
            >
                {strings.login.Login}
            </Button>
            <PanelBar>
                <PanelBarItem
                    title={strings.login.LoginDialog}
                    expanded={style === DelegatedAuthStyle.manualRedirectionWithVisibleForm}
                >
                    <div style={{ padding: "1rem" }}>
                        <LogInForm onCodeClear={() => setCodeValid(false)} />
                    </div>
                </PanelBarItem>
            </PanelBar>
            {secondaryText}
            <br />
            <AuthErrorRenderer />
        </div>
    );
}
