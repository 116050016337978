import { Slide } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { resetNotification } from "../../redux/actions/app";
import classes from "./PageLayout.module.scss";

interface IProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    onScrollPrevious?: () => void;
    onScrollNext?: () => void;
    canScrollPrevious?: boolean;
    canScrollNext?: boolean;
}

export function PageLayout(props: React.PropsWithChildren<IProps>) {
    const notificationVisible = useAppSelector(s => s.app.notificationVisible);
    const notificationData = useAppSelector(s => s.app.notificationData);
    const errorResponse = useAppSelector(s => s.app.errorResponse);
    const successResponse = useAppSelector(s => s.app.successResponse);
    const dispatch = useDispatch();
    const strings = useStrings();
    const getString = () =>
        successResponse
            ? strings.responses[successResponse as keyof typeof strings.responses]
            : strings.responses[errorResponse as keyof typeof strings.responses];

    useEffect(() => {
        if (notificationVisible)
            setTimeout(() => dispatch(resetNotification()), errorResponse === "err_labor_law" ? 10000 : 3000);
    }, [notificationVisible, errorResponse, dispatch]);

    return (
        <>
            <div className={classes.layout}>
                <header>{props.header}</header>
                <main className={classes.contentContainer}>{props.children}</main>
                <footer>{props.footer}</footer>
                {props.onScrollPrevious && (
                    <Button
                        className={classes.previousButton}
                        icon="arrow-chevron-left"
                        disabled={!props.canScrollPrevious}
                        onClick={props.onScrollPrevious}
                    />
                )}
                {props.onScrollNext && (
                    <Button
                        className={classes.nextButton}
                        icon="arrow-chevron-right"
                        disabled={!props.canScrollNext}
                        onClick={props.onScrollNext}
                    />
                )}
            </div>
            <NotificationGroup className={classes.notificationTop}>
                <Slide direction={!notificationVisible ? "up" : "down"}>
                    {notificationVisible && (
                        <Notification
                            type={{ style: successResponse ? "success" : "error", icon: true }}
                            closable
                            onClose={() => dispatch(resetNotification())}
                        >
                            <span>
                                {getString()}
                                {notificationData?.map((m, i) => (
                                    <span key={i}>
                                        <br />
                                        {m}
                                    </span>
                                ))}
                            </span>
                        </Notification>
                    )}
                </Slide>
            </NotificationGroup>
        </>
    );
}
