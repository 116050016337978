import React from "react";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "../MonthPicker/MonthPicker.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function MonthPicker(props: { onPick: (month: Date) => void; selectedMonth: Date }) {
    const language = useAppSelector(s => s.localization.language);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);
        const month = new Date(event.value.getFullYear(), event.value.getMonth(), 1);
        props.onPick(month);
    };

    const month = new Date(props.selectedMonth.getFullYear(), props.selectedMonth.getMonth(), 1);
    return (
        <>
            <div className={styles.flex}>
                <Button className={styles.monthButt} onClick={() => setIsOpen(true)}>
                    <span>
                        {props.selectedMonth.toLocaleString(language.code, {
                            month: "long",
                        })}
                        &nbsp;{props.selectedMonth.getFullYear()}
                    </span>
                </Button>
                <Button
                    icon="arrow-left"
                    className={styles.buttons}
                    onClick={() => props.onPick(props.selectedMonth.addMonths(-1))}
                ></Button>
                <Button
                    icon="arrow-right"
                    className={styles.buttons}
                    onClick={() => props.onPick(props.selectedMonth.addMonths(1))}
                ></Button>
            </div>
            {isOpen && (
                <div className={styles.window}>
                    <Dialog>
                        <Calendar
                            bottomView="year"
                            topView="decade"
                            navigation={false}
                            defaultValue={month}
                            onChange={handleChange}
                        />
                    </Dialog>
                </div>
            )}
        </>
    );
}
