import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { QueryParam2, ReportParam } from "../../model/CommonQueryParams";
import { ReportControls, ReportItem } from "../../model/ReportItem";

const DOMAIN = "reports";

export const fetchReportData = createAsyncThunk<
    {},
    QueryParam2<string, ReportParam<string, string>[]>,
    { state: IRootState }
>(`${DOMAIN}/fetchReportData`, async (arg, api) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
    if (arg != null) {
        if (arg.Value2 != null) {
            arg.Value2.push({ Item1: "lang", Item2: api.getState().localization.language.code });
        }
        const classname = api
            .getState()
            .session.reportItems.data?.find(f => f.classname.startsWith(arg.Value ?? ""))?.classname;
        arg.Value = classname ?? arg.Value;
    }

    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(arg),
    };
    const response = await fetch(
        api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetReport",
        requestOptions
    );

    const data = await response.json();

    if (response.status === 200) return data;
    else return api.rejectWithValue(data);
});

export const fetchReportsItems = createAsyncThunk<ReportItem[], void, { state: IRootState }>(
    `${DOMAIN}/fetchReportsItems`,
    async (arg, api) => {
        let requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/GetReportsForSelfService",
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) {
            const reports = data as ReportItem[];
            reports.forEach(r => {
                r.param.forEach(p => {
                    if (p.control && !ReportControls.has(p.control)) {
                        console.error("Neznámý parametr reportu", r.uid, p.control);
                    }
                    if (p.control === "none" && p.type !== "Boolean") {
                        console.error("Nepodporovaný typ parametru reportu", r.uid, p.type);
                    }
                });
            });
            return data;
        } else return api.rejectWithValue(data);
    }
);
