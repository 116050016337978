import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { IDashboardData } from "../../model/dashboard/IDashboardData";
import { handleResponseError } from "../../helpers/handleResponseError";
import { selectEndpoint } from "../selectors/selectEndpoint";
import { AppThunkAction } from "../../model/redux/AppThunkAction";

export const fetchDashboardData = createAsyncThunk<IDashboardData, void, { state: IRootState }>(
    "dashboard/data/fetch",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": api.getState().localization.language.code,
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/DashboardData",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export function updatePersonalPreferences(): AppThunkAction<void> {
    return async (dispatch, getState) => {
        const locale = getState().localization.language.code;

        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: locale,
                Item2: getState().session.substitution.substitutions.filter(
                    f => (f.deleted && !f.new) || f.edited || (f.new && !f.deleted)
                ),
            }),
        };

        const r = await fetch(
            selectEndpoint(getState()) + "/controllers/SelfService/UpdatePersonalPreferences",
            requestOptions
        );
        await handleResponseError(r);
    };
}
