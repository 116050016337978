import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { IPlanTimelineInterval } from "../../../model/plan/types";
import { IPerson } from "../../../model/plan/IPerson";
import { useStrings } from "../../../hooks/useStrings";
import { useFormatDate } from "../../../hooks/useFormatDate";
import styles from "./IntervalDetailDialog.module.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { cancelAwShift } from "../../../redux/actions/plan/my";

interface IOwnProps {
    interval: IPlanTimelineInterval;
    owner?: IPerson;
    onClose?: () => void;
    onCancel?: () => void;
}

export function IntervalDetailDialog(props: IOwnProps) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const formatDate = useFormatDate();

    const intr = props.interval;
    const parent = intr.parent;

    const cancelShift = () => {
        dispatch(cancelAwShift(intr.day)).then(() => {
            props.onCancel?.();
        });
    };

    return (
        <Dialog
            title={parent.type === "WFLREQUEST" ? strings.plan.RequestDetail : strings.plan.DetailPlan}
            closeIcon={false}
            width={"90%"}
            autoFocus
            onClose={() => props.onClose && props.onClose()}
        >
            {props.owner && (
                <Input
                    label={strings.common.Person}
                    value={`${props.owner.personalNumber} ${props.owner.name}`.replace(/ {2,}/, " ")}
                />
            )}
            <Input label={strings.common.NameNote} value={parent.name} />
            {parent.shortcut && <Input label={strings.common.Shortcut} value={parent.shortcut} />}
            <Input label={strings.common.Begin} value={formatDate(props.interval.parent.begin, "g")} />
            <Input label={strings.common.End} value={formatDate(props.interval.parent.end, "g")} />
            {intr.parent.type === "IPOPOH" && (
                <div className={styles.paramRow}>
                    <label>{strings.plan.OutsideOfWorkplace + ":"}</label>
                    <Checkbox className={styles.check} checked={intr.parent.outsideOfWorkplace}></Checkbox>
                </div>
            )}
            {intr.parent.center && <Input label={strings.freeShifts.Center} value={intr.parent.center} />}
            {intr.parent.category && <Input label={strings.freeShifts.Category} value={intr.parent.category} />}
            <DialogActionsBar>
                <Button onClick={props.onClose}>{strings.common.Close}</Button>
                {intr.parent.canCancel && (
                    <Button onClick={cancelShift} style={{ color: "red" }}>
                        {strings.freeShifts.CancelShift}
                    </Button>
                )}
            </DialogActionsBar>
        </Dialog>
    );
}

export default IntervalDetailDialog;
