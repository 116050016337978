import React, { MouseEventHandler } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Requests.module.scss";
import { Input } from "@progress/kendo-react-inputs";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { wflChangeState } from "../../redux/actions/wflStateChange";
import { QueryParam3 } from "../../model/CommonQueryParams";
import { WflReq } from "../../model/wflReq";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { Error } from "@progress/kendo-react-labels";
import classes from "./RequestDetail.module.scss";
import { remainingDaysString } from "../../utils/LocalizationUtils";

class RequestDetail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            reqiredNote: false,
        };
    }
    private StateResolver = (state: number) => {
        const strings = this.props.strings;
        if (state === 0) {
            return (
                <Input
                    readOnly
                    className={styles.created}
                    label={strings.requests.StateName}
                    value={strings.requests.CreatedReq}
                />
            );
        } else if (state === 1) {
            return (
                <Input
                    readOnly
                    className={styles.approved}
                    label={strings.requests.StateName}
                    value={strings.requests.Approved}
                />
            );
        } else if (state === 2) {
            return (
                <Input
                    readOnly
                    className={styles.rejected}
                    label={strings.requests.StateName}
                    value={strings.requests.Rejected}
                />
            );
        } else {
            return <Input readOnly label={strings.requests.StateName} value={strings.requests.Canceled} />;
        }
    };

    private IconResolver = (state: number) => {
        if (state === 0) {
            return <span style={{ color: "blue" }} className={"k-icon k-i-clock"}></span>;
        } else if (state === 1) {
            return <span style={{ color: "green" }} className={"k-icon k-i-check"}></span>;
        } else if (state === 2) {
            return <span style={{ color: "red" }} className={"k-icon k-i-close"}></span>;
        }
    };

    private getRemainingDays(RemainingDays: number): string {
        const strings = this.props.strings;
        return remainingDaysString(strings, RemainingDays);
    }

    private ApproverInfoResolver = (state: number) => {
        const strings = this.props.strings;
        if (state === 1) {
            return (
                <Input
                    readOnly
                    label={strings.requests.ApprovedBy}
                    value={this.props.data.Approver + " " + this.props.data.DateModified}
                />
            );
        } else if (state === 2) {
            return (
                <Input
                    readOnly
                    label={strings.requests.RejectedBy}
                    value={this.props.data.Approver + " " + this.props.data.DateModified}
                />
            );
        }
    };

    async onChangeState(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: number): Promise<void> {
        let note: string = this.state !== null && this.state.comment !== undefined ? this.state.comment : "";
        let queryParam: QueryParam3<number, number, string> = {
            Value: this.props.data.ID,
            Value2: status,
            Value3: note,
        };
        if (status === 2 && note === "" && !this.props.reqNotePerm) {
            this.setState({ reqiredNote: true });
        } else {
            await this.props.onStateChange(queryParam);
            this.props.onClose && this.props.onClose(event);
        }
    }

    public render() {
        return (
            <Dialog
                title={
                    <div className={styles.dialogTitle}>
                        <label>{this.props.strings.requests.RequestSingle}</label>
                        {this.IconResolver(this.props.data.State)}
                    </div>
                }
                width="90%"
                closeIcon={false}
            >
                <Input readOnly label={this.props.strings.worksheet.NameWS} value={this.props.data.Applicant} />
                <Input readOnly label={this.props.strings.requests.Type} value={this.props.data.ReqText} />
                <Input
                    readOnly
                    label={this.props.strings.requests.CreatedReq}
                    value={this.props.data.Created + " - " + this.getRemainingDays(this.props.data.RemainingDaysFloor)}
                />
                {this.StateResolver(this.props.data.State)}
                {this.ApproverInfoResolver(this.props.data.State)}
                {this.props.data.Note ? (
                    <Input readOnly label={this.props.strings.requests.Comment} value={this.props.data.Note} />
                ) : (
                    <></>
                )}
                {this.props.isApprover && this.props.data.State === 0 ? (
                    <div>
                        <Input
                            label={this.props.strings.requests.Comment}
                            valid={!this.state.reqiredNote}
                            onChange={e => this.setState({ comment: e.value })}
                        />
                        {this.state.reqiredNote && !this.props.reqNotePerm && (
                            <Error>{this.props.strings.requests.ErrorRequiredNote}</Error>
                        )}
                    </div>
                ) : (
                    <></>
                )}

                <DialogActionsBar>
                    <Button onClick={this.props.onClose}>{this.props.strings.common.Close}</Button>
                    {/* Delete only when state is created - pending */}
                    {this.props.data.State === 0 && !this.props.isApprover && (
                        <PermissionGuard permission={permissions.plan.absence}>
                            <Button className={classes.cancel} onClick={event => this.onChangeState(event, 3)}>
                                {this.props.strings.requests.CancelRequest}
                            </Button>
                        </PermissionGuard>
                    )}
                    {/* approving and rejecting is allowed only in approver module */}
                    {this.props.isApprover && this.props.data.State === 0 && (
                        <Button onClick={event => this.onChangeState(event, 1)} style={{ color: "green" }}>
                            {this.props.strings.common.Approve}
                        </Button>
                    )}
                    {this.props.isApprover && this.props.data.State === 0 && (
                        <Button onClick={event => this.onChangeState(event, 2)} style={{ color: "red" }}>
                            {this.props.strings.requests.Reject}
                        </Button>
                    )}
                </DialogActionsBar>
            </Dialog>
        );
    }
}

interface IOwnProps {
    onClose?: MouseEventHandler;
    data: WflReq;
    isApprover: boolean;
}

interface IStateProps {
    strings: IStrings;
    reqNotePerm: boolean;
}

interface IState {
    comment?: string;
    reqiredNote?: boolean;
}

interface IDispatchProps {
    onStateChange: (e: QueryParam3<number, number, string>) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & IState;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        reqNotePerm:
            state.session.user.token?.userDisabledActions.some(p => p === permissions.requestsForApproval.rejectNote) ??
            false,
    }),
    {
        onStateChange: (e: QueryParam3<number, number, string>) => wflChangeState(e),
    }
)(RequestDetail);
