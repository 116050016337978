export interface ReportItem {
    caption: string;
    uid: string;
    classname: string;
    param: ReportItemParam[];
    permission?: string;
}

export interface ReportItemParam {
    name: string;
    label: string;
    type: "String" | "Integer" | "Boolean" | "DateTime" | "Float";
    control: ReportControl;
    options: string;
    visible: boolean;
}

export type ReportControl = typeof ReportControls extends Set<infer T> ? T : never;

export const ReportControls = new Set([
    "none",
    // "substr",
    // "center",
    // "centersmulti",
    // "allcentersmulti",
    "date",
    "datemonth",
    // "dateweek",
    // "dateyear",
    // "time",
    "person",
    "persons",
    // "account",
    "accounts",
    // "professions",
    // "costcenter",
    // "agency",
    // "agencywithall",
    // "categorie",
    // "categories",
    // "categorieswithall",
    // "timetaskwithfolders",
    // "timetask",
    // "timetasks",
    "selectone",
    // "selectonesql",
    // "selectmultisql",
    // "selectmulti",
    // "statisticsTesco",
    // "userid",
    // "username",
    // "holiday",
    // "doctor",
    "specifikace",
    // "bonus",
    // "bonuses",
    // "model",
    // "models",
    // "commonshiftsmodels",
    // "wagetype",
    // "wagetypes",
    // "gdprremovedperson",
    // "gdprremovedpersons",
    // "gdprremovingperson",
    // "gdprremovingpersons",
    // "sensitivedatachanger",
    // "sensitivedatachangers",
    // "sensitivedataowner",
    // "sensitivedataowners",
    // "mealtickettypes",
    // "mealtickettypesAll",
    // "zurtable",
    // "accesspointsmulti",
    // "customFieldDropDown1",
    // "passesmulti",
    // "timeonly",
    // "agendasbycustomer",
] as const);
