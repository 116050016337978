import React from "react";
import classes from "./ErrorsButton.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { errorsVisibilitySlice } from "../../redux/slices/errorsVisibility";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function ErrorsButton() {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const count = useAppSelector(s => s.errors.list.length);
    return (
        <Button
            title={strings.debug.ShowError}
            icon="preview"
            className={classes.button}
            onClick={() => dispatch(errorsVisibilitySlice.actions.set(true))}
        >
            {`${strings.debug.ShowError} (${count})`}
        </Button>
    );
};
