import { IRootState } from "../reducers/root";

export function selectIxsRef(state: IRootState): string {
    return (
        (state.session.user.token?.sidSubst !== ""
            ? state.session.user.token?.sidSubst
            : state.session.user.token?.sid) ?? ""
    );
}

// uživatel, který prošel přihlášením
export function selectAuthIxsRef(state: IRootState): string {
    const user = state.session.userList.users.find(f => f.ixs_ref === state.session.userList.selectedIxsRef);
    if (user?.poradi == null) return state.session.user.token?.sid ?? "";
    else return selectIxsRef(state);
}
