import React from "react";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "./NavbarMonthPicker.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import ReactDOM from "react-dom";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function NavbarMonthPicker(props: { onPick: (month: Date) => void; selectedMonth: Date }) {
    const language = useAppSelector(s => s.localization.language);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);
        const month = new Date(event.value.getFullYear(), event.value.getMonth(), 1);
        props.onPick(month);
    };

    const month = new Date(props.selectedMonth.getFullYear(), props.selectedMonth.getMonth(), 1);

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>
                <span>
                    {props.selectedMonth.toLocaleString(language.code, {
                        month: "long",
                    })}
                </span>
                <span className={styles.year}>&nbsp;{props.selectedMonth.getFullYear()}</span>
            </Button>
            {isOpen &&
                ReactDOM.createPortal(
                    <Dialog className={styles.window} onClose={() => setIsOpen(false)} autoFocus>
                        <Calendar
                            bottomView="year"
                            topView="decade"
                            navigation={false}
                            defaultValue={month}
                            onChange={handleChange}
                        />
                    </Dialog>,
                    document.body
                )}
        </>
    );
}
