import React, { Key, useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { errorsListSlice, IErrorContainer } from "../../redux/slices/errorsList";
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { Button } from "@progress/kendo-react-buttons";
import { errorsVisibilitySlice } from "../../redux/slices/errorsVisibility";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export default function ErrorsDialog() {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const errors = useAppSelector(s => s.errors.list);
    const [lastCopyInfo, setLastCopyInfo] = useState<{ id: Key; text?: string } | undefined>();
    const [timeoutID, setTimeoutID] = useState<number>(0);

    const onClose = () => dispatch(errorsVisibilitySlice.actions.set(false));
    const onRemove = (id: Key) => dispatch(errorsListSlice.actions.remove(id));
    const onRemoveAll = () => dispatch(errorsListSlice.actions.removeAll());

    useEffect(() => () => clearTimeout(timeoutID), [timeoutID]);

    const handleListItemClick = (e: IErrorContainer) => {
        clearTimeout(timeoutID);
        const text = JSON.stringify(e, null, "\t");

        if (window.isSecureContext) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setLastCopyInfo({
                        id: e.id,
                        text: strings.debug.Msg_DetailsSuccessfullyCopiedIntoClipboard,
                    });
                })
                .catch(reason => {
                    console.error(reason);
                    setLastCopyInfo({
                        id: e.id,
                        text: reason,
                    });
                })
                .finally(() => {
                    const id = setTimeout(() => setLastCopyInfo(undefined), 3000) as unknown as number;
                    setTimeoutID(id);
                });
        } else if (!!document.execCommand) {
            unsecuredCopyToClipboard(text);
            setLastCopyInfo({
                id: e.id,
                text: strings.debug.Msg_DetailsSuccessfullyCopiedIntoClipboard,
            });
            const id = setTimeout(() => setLastCopyInfo(undefined), 3000) as unknown as number;
            setTimeoutID(id);
        }
    };

    function unsecuredCopyToClipboard(text: string) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Unable to copy to clipboard", err);
        }
        document.body.removeChild(textArea);
    }

    return (
        <Dialog title={strings.debug.ErrorsMany} width="100%" height="100%" onClose={onClose}>
            <List>
                {errors.map(e => (
                    <ListItem key={e.id} divider button onClick={() => handleListItemClick(e)}>
                        <ListItemText
                            primary={`#${e.id} ${e.error.message}`}
                            primaryTypographyProps={{ noWrap: true }}
                            secondary={
                                lastCopyInfo?.id === e.id
                                    ? lastCopyInfo.text
                                    : strings.debug.Msg_ClickForCopyDetailsIntoClipboard
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton title={strings.common.Delete} edge="end" onClick={() => onRemove(e.id)}>
                                <DeleteForever />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <DialogActionsBar>
                <Button
                    title={`${strings.common.DeleteAll} & ${strings.common.Close}`}
                    icon="delete"
                    onClick={() => {
                        onRemoveAll();
                        onClose();
                    }}
                >
                    {`${strings.common.DeleteAll} & ${strings.common.Close}`}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
