import React from "react";
import styles from "./InfoPanel.module.scss";
import classNames from "classnames";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { Balance } from "./Balance";
import { Vacation } from "./Vacation";
import { DateTimeFormatter } from "../../components/DateTimeFormatter";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { fetchSelectedUserTicket } from "../../redux/actions/userList";
import { fetchToken } from "../../redux/actions/user";
import { fetchDashboardData } from "../../redux/actions/dashboard";
import { useStrings } from "../../hooks/useStrings";

export default function InfoPanel() {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const fullname = useAppSelector(selectUserFullname);
    const userList = useAppSelector(s => s.session.userList.users);
    const selectedIxsref = useAppSelector(s => s.session.userList.selectedIxsRef);
    const tokenFetching = useAppSelector(s => s.session.user.isAuthenticating);
    const dataFetching = useAppSelector(s => s.session.dashboard.isFetching);
    const data = useAppSelector(s => s.session.dashboard.data);
    return (
        <div className={styles.panel}>
            <div className={styles.info}>
                <h2 className={classNames(styles.gutterBottom, styles.textPerson)} style={{ display: "flex" }}>
                    {!(userList.length > 1) && <b>{fullname}</b>}
                    {userList.length > 1 && (
                        <DropDownList
                            disabled={tokenFetching || dataFetching}
                            style={{ flex: "1", fontFamily: "inherit", fontSize: "inherit" }}
                            textField="fullname"
                            dataItemKey="ixs_ref"
                            value={userList.find(f => f.ixs_ref === selectedIxsref)}
                            onChange={e => {
                                dispatch(
                                    fetchSelectedUserTicket({
                                        poradi: e.target.value.poradi,
                                        idZast: e.target.value.id_zast,
                                    })
                                ).then(res =>
                                    dispatch(
                                        fetchToken({
                                            credentials: {
                                                username: res.payload as string,
                                                password: "",
                                            },
                                        })
                                    ).then(() => {
                                        dispatch(fetchDashboardData());
                                    })
                                );
                            }}
                            data={userList}
                        />
                    )}
                </h2>
                {userList.length > 1 && (
                    <div className={styles.gutterBottom}>
                        {strings.common.Center + ": " + userList.find(f => f.ixs_ref === selectedIxsref)?.str_text}
                    </div>
                )}
                <PermissionGuard permission={permissions.dashboard.balance}>
                    {data?.balance && (
                        <div className={styles.gutterBottom}>
                            {strings.dashboard.Balance + ":"} <Balance />
                        </div>
                    )}
                </PermissionGuard>
                <div className={styles.gutterBottom}>
                    <PermissionGuard permission={permissions.dashboard.vacation}>
                        <Vacation vacation={data?.vacation} defaultName={strings.dashboard.VacationBalance} />
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.dashboard.addVacation}>
                        <br />
                        <Vacation vacation={data?.addVacation} defaultName={strings.dashboard.AdditionalVacation} />
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.dashboard.addVacation2}>
                        <br />
                        <Vacation vacation={data?.addVacation2} defaultName={strings.dashboard.AdditionalVacation2} />
                    </PermissionGuard>
                </div>
                {data?.lastPassage && (
                    <div>
                        <span>
                            <DateTimeFormatter value={new Date(data?.lastPassage.time)} format="g" />
                        </span>
                        <span> </span>
                        <span>{data?.lastPassage.text?.toLocaleUpperCase()}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
