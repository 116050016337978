import { handleResponseError } from "../../helpers/handleResponseError";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { IUserList } from "../../model/IUserList";

const DOMAIN = "UserList";

export const fetchSelectedUserTicket = createAsyncThunk<string, { poradi?: number; idZast?: number }, { state: IRootState }>(
    `${DOMAIN}/fetchSelectedUser`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            arg.poradi !== null
                ? api.getState().config.config.endpoint +
                      "/breeze/IpoData/GetAuthUserTicketSelfService?poradi=" +
                      arg.poradi
                : api.getState().config.config.endpoint +
                      "/breeze/IpoData/GetAuthUserTicket?idZast=" +
                      (arg.idZast ?? -1),
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const fetchUserList = createAsyncThunk<IUserList, void, { state: IRootState }>(
    `${DOMAIN}/fetchUserList`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config.endpoint + "/breeze/IpoData/GetUserList",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
