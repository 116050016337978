import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";
import { List, ListItem } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setAwPreferred } from "../../redux/actions/agreementWorkers";

export default function AwCenterDialog(props: { onClose?: (refresh: boolean) => void }) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const [centers, setCenters] = useState(useAppSelector(s => s.session.freeShifts.centers));
    const loading = useAppSelector(s => s.session.freeShifts.setIsFetching);

    const onSubmit = () => {
        dispatch(setAwPreferred(centers)).then(() => props.onClose?.(true));
    };

    return (
        <Dialog title={strings.freeShifts.CentersConfig} minWidth="300px" closeIcon={false}>
            <List>
                {centers.map((m, i) => (
                    <ListItem key={m.ixsStr}>
                        <Checkbox
                            value={m.preferred}
                            label={m.name}
                            onChange={e =>
                                setCenters([
                                    ...centers.slice(0, i),
                                    { ...m, preferred: e.value },
                                    ...centers.slice(i + 1),
                                ])
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <DialogActionsBar>
                <Button disabled={loading} onClick={() => props.onClose?.(false)}>
                    {strings.common.Close}
                </Button>
                <Button disabled={loading} onClick={onSubmit} icon={loading ? "loading" : ""}>
                    {loading ? "" : strings.common.Confirm}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
