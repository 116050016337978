import moment from "moment";
import { DeserializedInterval } from "../../../model/plan/types";

export function resolveTitle(intr: DeserializedInterval): string {
    if (intr.deleted) return intr.name;

    if (intr.type === "IPODENSMEN") {
        return `${moment(intr.begin).format("HH:mm")}-${moment(intr.end).format("HH:mm")}`;
    }

    const title = intr.shortcut?.trim() ?? intr.name?.trim();

    if (intr.type === "WFLREQUEST") return `(${title})`;

    return title;
}
