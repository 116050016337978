import React, { useEffect } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { fetchLastPasses } from "../../redux/actions/passesLoader";
import styles from "./LastPasses.module.scss";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { LastPass } from "../../model/terminal/IPOPRT";
import { Button } from "@progress/kendo-react-buttons";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../components/ErrorNotification";
import { dateOptions, timeOptions } from "../../constants/dates";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export default function LastPasses() {
    const strings = useAppSelector(s => s.localization.strings);
    const lang = useAppSelector(s => s.localization.language);
    const rows = useAppSelector(s => s.session.lastPasses.passes);
    const isFetching = useAppSelector(s => s.session.lastPasses.isFetching);
    const error = useAppSelector(s => s.session.lastPasses.error);
    const dispatch = useAppDispatch();

    const getCorrectDateTimeFormat = (date: Date): string => {
        const datum: Date = new Date(date);
        return datum.toLocaleString(lang.code, dateOptions) + " " + datum.toLocaleTimeString(lang.code, timeOptions);
    };

    useEffect(() => {
        dispatch(fetchLastPasses());
    }, [dispatch]);

    const showPosition = (GPS: string | undefined): void => {
        if (GPS != null) {
            window.open("https://maps.google.com?q=" + GPS);
        }
    };

    return (
        <PageLayout
            header={<NavBar canNavigateBack previousPage={"/online-terminal"} label={strings.lastPasses.TitleLP} />}
        >
            {isFetching ? (
                <SpinnerBox />
            ) : (
                <>
                    {error ? (
                        <ErrorNotification error={error} />
                    ) : (
                        <List>
                            {!rows?.length && <label className={styles.noPasses}>{strings.lastPasses.NoPasses}</label>}
                            {/* řádky */}
                            {rows?.map((row: LastPass, key: any) => (
                                <ListItem key={key} divider dense button>
                                    <ListItemText
                                        primary={
                                            <>
                                                <div className={styles.firstRow}>
                                                    <label>{getCorrectDateTimeFormat(row.Date)}</label>
                                                    <label className={styles.typeStyle}>{row.Type}</label>
                                                </div>
                                                <label>{row.FullName}</label>
                                            </>
                                        }
                                        secondary={row.Note && <label>{row.Note}</label>}
                                    />
                                    <ListItemSecondaryAction>
                                        {row.GPS !== "" && row.GPS != null && (
                                            <div className={styles.icon}>
                                                <Button
                                                    onClick={() => showPosition(row.GPS)}
                                                    iconClass="k-icon k-i-marker-pin-target"
                                                ></Button>
                                            </div>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            )}
        </PageLayout>
    );
}
