import React, { useState } from "react";
import { useFormatDate } from "../../hooks/useFormatDate";
import { AwShift } from "../../redux/reducers/agreementWorkers";
import Accounts from "../Plan/shared/Accounts";
import { IAccount } from "../../model/plan/types";
import styles from "./FreeShiftGrid.module.scss";
import { useStrings } from "../../hooks/useStrings";
import { sameDay } from "../../utils/DateUtils";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";

const DAY_LEN = 24 * 60 * 60 * 1000;

const getStartPerc = (day: Date, start: Date) =>
    `${(100 * timeDiff(new Date(day.getFullYear(), day.getMonth(), day.getDate()), start)) / DAY_LEN}%`;

const getWidthPerc = (start: Date, end: Date) => `${(100 * timeDiff(start, end)) / DAY_LEN}%`;

const timeDiff = (a: Date, b: Date) => b.getTime() - a.getTime();

const daysInMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

const dayByIndex = (date: Date, index: number) => new Date(date.getFullYear(), date.getMonth(), index + 1);

const shiftsByDay = (shifts: AwShift[], day: Date) =>
    shifts.filter(
        f =>
            sameDay(new Date(f.start.min ?? f.start.default), day) ||
            sameDay(new Date(f.end.max ?? f.end.default), day) ||
            (new Date(f.start.min ?? f.start.default) < day && new Date(f.end.max ?? f.end.default) > day)
    );

export default function FreeShiftGrid(props: {
    month: Date;
    shifts: AwShift[];
    categories: IAccount[];
    onSelect: (shift: AwShift) => void;
    loading: boolean;
}) {
    const strings = useStrings();
    const [hovered, setHovered] = useState<number | undefined>();
    const categories = props.categories.filter(f => f.id !== 0);

    return props.loading ? (
        <SpinnerBox stretchToWindow />
    ) : (
        <div className={styles.grid}>
            <Header />
            {[...Array(daysInMonth(props.month))].map((_, i) => (
                <DayRow
                    setHoverd={setHovered}
                    hovered={hovered}
                    onSelect={props.onSelect}
                    key={i}
                    day={dayByIndex(props.month, i)}
                    shifts={shiftsByDay(props.shifts, dayByIndex(props.month, i))}
                    categories={props.categories}
                />
            ))}
            {categories.length > 0 && (
                <div className={styles.accounts}>
                    <Accounts title={strings.freeShifts.Categories} accounts={categories} />
                </div>
            )}
        </div>
    );
}

function Header() {
    return (
        <div className={styles.header}>
            <div />
            <div>
                {[...Array(12)].map((_, i) => (
                    <div key={i}>{i * 2}</div>
                ))}
            </div>
        </div>
    );
}

function Interval(props: {
    day: Date;
    shift: AwShift;
    color?: string;
    kind: "firstPart" | "secondPart" | "overlay";
    onSelect?: (shift: AwShift) => void;
    hovered?: number | undefined;
    setHoverd?: (id: number | undefined) => void;
}) {
    const start =
        props.kind === "firstPart"
            ? new Date(props.shift.start.max ?? props.shift.start.default)
            : props.kind === "secondPart"
              ? new Date(props.shift.break.end ?? props.shift.start.max ?? props.shift.start.default)
              : new Date(props.shift.start.min ?? props.shift.start.default);
    const end =
        props.kind === "firstPart"
            ? new Date(props.shift.break.start ?? props.shift.end.min ?? props.shift.end.default)
            : props.kind === "secondPart"
              ? new Date(props.shift.end.min ?? props.shift.end.default)
              : new Date(props.shift.end.max ?? props.shift.end.default);

    const isOverlay = props.kind === "overlay";
    const className = isOverlay
        ? props.hovered === props.shift.id
            ? styles.shiftOverlayHover
            : styles.shiftOverlay
        : styles.shift;
    return (
        <div
            onMouseEnter={() => props.setHoverd?.(props.shift.id)}
            onMouseLeave={() => props.setHoverd?.(undefined)}
            onClick={() => props.onSelect?.(props.shift)}
            className={className}
            style={{
                left: getStartPerc(props.day, start),
                width: getWidthPerc(start, end),
                background: isOverlay
                    ? `repeating-linear-gradient( -45deg, transparent 0 3px, ${props.color} 3px 6px )`
                    : props.color,
            }}
        >
            {isOverlay ? props.shift.center.name : undefined}
        </div>
    );
}

function DayRow(props: {
    day: Date;
    shifts: AwShift[];
    categories: IAccount[];
    onSelect: (shift: AwShift) => void;
    hovered: number | undefined;
    setHoverd: (id: number | undefined) => void;
}) {
    const formatDate = useFormatDate();
    return (
        <div className={styles.day}>
            <div>{formatDate(props.day, "EEE d")}</div>
            <div>
                <div>
                    {props.shifts.map(m => {
                        const color = props.categories.find(f => f.id === m.category)?.color;
                        return (
                            <div key={m.id} test-planid={m.id}>
                                <Interval shift={m} day={props.day} color={color} kind="firstPart" />
                                <Interval shift={m} day={props.day} color={color} kind="secondPart" />
                                <Interval
                                    onSelect={props.onSelect}
                                    shift={m}
                                    day={props.day}
                                    color={color}
                                    kind="overlay"
                                    hovered={props.hovered}
                                    setHoverd={props.setHoverd}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
