import { useAppSelector } from "../../hooks/useAppSelector";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { List, ListItem } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { resetAwData, setAwCheckedCenters } from "../../redux/actions/agreementWorkers";

export default function AwCenterPicker(props: { onClose?: () => void }) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const centers = useAppSelector(s => s.session.freeShifts.centers);
    const [checked, setChecked] = useState(useAppSelector(s => s.session.freeShifts.checkedCenters));
    const isAllChecked = (c: string[]) => {
        const preferred = centers.filter(f => f.preferred);
        const preferredChecked = preferred.filter(f => c.includes(f.ixsStr));
        if (preferred.length === preferredChecked.length) return true;
        if (preferredChecked.length === 0) return false;
        return null;
    };

    const [allChecked, setAllChecked] = useState(isAllChecked(checked));

    const toggleChecked = (ixsstr: string) => {
        const newChecked = checked.includes(ixsstr) ? checked.filter(f => f !== ixsstr) : [...checked, ixsstr];
        setChecked(newChecked);
        setAllChecked(isAllChecked(newChecked));
    };

    const toggleAllChecked = () => {
        const preferred = centers.filter(f => f.preferred).map(m => m.ixsStr);
        if (allChecked) {
            const newChecked = checked.filter(f => !preferred.includes(f));
            setChecked(newChecked);
        } else {
            const newChecked = [...checked, ...preferred].filter((val, i, array) => array.indexOf(val) === i);
            setChecked(newChecked);
        }
        setAllChecked(!allChecked);
    };

    const onSubmit = () => {
        dispatch(setAwCheckedCenters(checked));
        dispatch(resetAwData());
        props.onClose?.();
    };

    return (
        <Dialog title={strings.freeShifts.CenterSelection} minWidth="300px" closeIcon={false}>
            {centers.filter(f => f.preferred).length > 0 && (
                <>
                    <div style={{ display: "flex" }}>
                        <span style={{ flexGrow: 1 }}>
                            {centers.filter(f => !f.preferred).length > 0
                                ? strings.freeShifts.Preferred
                                : strings.freeShifts.All}
                        </span>
                        <Checkbox checked={allChecked} onChange={toggleAllChecked} onClick={toggleAllChecked} />
                    </div>
                    <List>
                        {centers
                            .filter(f => f.preferred)
                            .map(m => (
                                <ListItem key={m.ixsStr}>
                                    <Checkbox
                                        checked={checked.includes(m.ixsStr)}
                                        label={m.name}
                                        onChange={() => toggleChecked(m.ixsStr)}
                                    />
                                </ListItem>
                            ))}
                    </List>
                </>
            )}
            {centers.filter(f => !f.preferred).length > 0 && (
                <>
                    {centers.filter(f => f.preferred).length > 0 && <span>{strings.freeShifts.Others}</span>}
                    <List>
                        {centers
                            .filter(f => !f.preferred)
                            .map((m, i) => (
                                <ListItem key={m.ixsStr}>
                                    <Checkbox
                                        checked={checked.includes(m.ixsStr)}
                                        label={m.name}
                                        onChange={() => toggleChecked(m.ixsStr)}
                                    />
                                </ListItem>
                            ))}
                    </List>
                </>
            )}
            <DialogActionsBar>
                <Button onClick={props.onClose}>{strings.common.Close}</Button>
                <Button onClick={onSubmit}>{strings.common.Confirm}</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
