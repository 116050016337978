import { IStrings } from "../constants/languageStrings/IStrings";

export const formatString = (string: string, value0: string | number, value1?: string | number) =>
    string.replaceAll("$0$", value0.toString()).replaceAll("$1$", value1?.toString() ?? "");

export const remainingDaysString = (strings: IStrings, days: number) => {
    if (days < 0) return strings.requests.Expired;
    else if (days === 0) return formatString(strings.requests.RemainingDays, 0);
    else if (days < 2) return strings.requests.RemainingDay;
    else if (days < 5) return formatString(strings.requests.RemainingDaysUpTo4, days);
    else return formatString(strings.requests.RemainingDays, days);
};
