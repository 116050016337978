import React, { useEffect, useRef, useState } from "react";
import styles from "./Stack.module.scss";
import { IInterval } from "../types";
import { GridPositioner } from "../positioning/GridPositioner";
import { generateDummyInterval } from "../generators";
import { AbsolutePositioner } from "../positioning/AbsolutePositioner";

const dummy = generateDummyInterval();

export default function Stack(props: { intervals: IInterval[] }) {
    const ref = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(0);

    const handleResize = () => {
        setWidth(ref.current?.clientWidth ?? 0);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div ref={ref} className={styles.stack}>
            {props.intervals.map(intr => (
                <AbsolutePositioner key={intr.id} stackWidth={width} interval={intr} />
            ))}
            <GridPositioner interval={dummy} />
        </div>
    );
}
