import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { IMyPlanData } from "../../../model/plan/my/IMyPlanData";
import getHeaders from "../../../helpers/getHeaders";
import GetResponseJson from "../../../helpers/getResponseJson";

type Arg = {
    month: Date;
};

const DATA_ADDRESS = "/breeze/IpoData/SelfService_GetMyPlanData";

export const fetchMyPlanData = createAsyncThunk<IMyPlanData, Arg, { state: IRootState }>(
    "plan/my/data/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: JSON.stringify({
                Item1: arg.month,
                Item2: api.getState().localization.language.code,
            }),
            signal: api.signal,
        };
        return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
    }
);

export const fetchMyPlanDataPrevious = createAsyncThunk<IMyPlanData, Arg, { state: IRootState }>(
    "plan/my/data/fetchPrevious",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: JSON.stringify({
                Item1: arg.month.addMonths(-1),
                Item2: api.getState().localization.language.code,
            }),
            signal: api.signal,
        };
        return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
    }
);
export const fetchMyPlanDataNext = createAsyncThunk<IMyPlanData, Arg, { state: IRootState }>(
    "plan/my/data/fetchNext",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: JSON.stringify({
                Item1: arg.month.addMonths(1),
                Item2: api.getState().localization.language.code,
            }),
            signal: api.signal,
        };
        return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
    }
);

export const scrollMyPlanPrevious = createAction("plan/my/previous");

export const scrollMyPlanNext = createAction("plan/my/next");

export const resetMyPlanData = createAction("plan/my/reset");

export const fetchOldestMonthWithMyPlanRequest = createAsyncThunk<string | null, void, { state: IRootState }>(
    "plan/my/oldestmonthWithPlanRequest/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: getHeaders(api),
            signal: api.signal,
        };
        const serializedDate = (await GetResponseJson(
            api,
            "/controllers/SelfService/OldestMonthWithMyPlanRequest",
            requestOptions
        )) as string | null;
        return serializedDate;
    }
);

export const cancelAwShift = createAsyncThunk<boolean, Date, { state: IRootState }>(
    "plan/my/cancelShift",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            signal: api.signal,
            body: JSON.stringify({ Value: arg }),
        };
        return await GetResponseJson(api, "/breeze/IpoData/CancelAwShift", requestOptions);
    }
);

