import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";
import { List, ListItem } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setAwTimes } from "../../redux/actions/agreementWorkers";
import styles from "./AwTimeDialog.module.scss";
import { TimePicker } from "@progress/kendo-react-dateinputs";

export default function AwTimeDialog(props: { onClose?: (refresh: boolean) => void }) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const [times, setTimes] = useState(useAppSelector(s => s.session.freeShifts.times));
    const loading = useAppSelector(s => s.session.freeShifts.setIsFetching);

    const onSubmit = () => {
        dispatch(setAwTimes(times)).then(() => props.onClose?.(true));
    };

    return (
        <Dialog title={strings.freeShifts.TimesConfig} closeIcon={false}>
            <List>
                {times.map((m, i) => (
                    <ListItem key={m.day} className={styles.item}>
                        <Checkbox
                            value={m.enabled}
                            label={strings.freeShifts.Days[i]}
                            onChange={e =>
                                setTimes([
                                    ...times.slice(0, i),
                                    { day: m.day, start: m.start, end: m.end, enabled: e.value },
                                    ...times.slice(i + 1),
                                ])
                            }
                        />
                        <TimePicker
                            value={new Date(m.start)}
                            width={80}
                            disabled={!m.enabled}
                            onChange={e =>
                                e.value &&
                                setTimes([
                                    ...times.slice(0, i),
                                    { day: m.day, start: e.value, end: m.end, enabled: m.enabled },
                                    ...times.slice(i + 1),
                                ])
                            }
                        />
                        <TimePicker
                            value={new Date(m.end)}
                            width={80}
                            disabled={!m.enabled}
                            onChange={e =>
                                e.value &&
                                setTimes([
                                    ...times.slice(0, i),
                                    { day: m.day, start: m.start, end: e.value, enabled: m.enabled },
                                    ...times.slice(i + 1),
                                ])
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <DialogActionsBar>
                <Button disabled={loading} onClick={() => props.onClose?.(false)}>
                    {strings.common.Close}
                </Button>
                <Button disabled={loading} onClick={onSubmit} icon={loading ? "loading" : ""}>
                    {loading ? "" : strings.common.Confirm}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
