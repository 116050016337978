import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { IPOABS } from "../../model/IPOABS";
import { QueryParam } from "../../model/CommonQueryParams";
import { INewRequestParams } from "../../model/INewRequestParams";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { RequestsAgend } from "../../model/wflReq";
import { APP_VERSION } from "../../constants/common";

const DOMAIN = "myRequests";

export const insertAbsencesToMultipleDates = createAsyncThunk<unknown, INewRequestParams, { state: IRootState }>(
    `${DOMAIN}/insertAbsencesToMultipleDates`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                Value: arg.objectID === RequestsAgend.Requests ? createIPOABSes(arg) : createPLNUNAVA(arg),
                Value2: APP_VERSION,
            } as QueryParam<IPOABS[]>),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint +
                (arg.objectID === RequestsAgend.Requests
                    ? "/breeze/IpoData/InsertAbsenceForSelfService"
                    : "/breeze/IpoData/InsertUnavaForSelfService"),
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) return data;
        else return api.rejectWithValue(data);
    }
);

function createPLNUNAVA(args: INewRequestParams): PLNUNAVA {
    const unava: PLNUNAVA = {
        IXS_REF: args.userID,
        DATE_REF: args.dayOfBegin,
        STARTTIME: new Date(
            args.dayOfBegin.getFullYear(),
            args.dayOfBegin.getMonth(),
            args.dayOfBegin.getDate(),
            args.timeOfBegin.getHours(),
            args.timeOfBegin.getMinutes()
        ),
        ENDTIME: new Date(
            args.dayOfBegin.getFullYear(),
            args.dayOfBegin.getMonth(),
            args.dayOfBegin.getDate(),
            args.timeOfEnd.getHours(),
            args.timeOfEnd.getMinutes()
        ),
        DATE_CREATE: new Date(),
        NOTE: args.comment,
    };
    if (unava.STARTTIME!.getTime() >= unava.ENDTIME!.getTime()) {
        unava.ENDTIME = unava.ENDTIME!.addDays(1);
    }
    return unava;
}

function createIPOABSes(args: INewRequestParams): IPOABS[] {
    if (args.dayOfBegin > args.dayOfEnd) throw new Error("Day of begin has to be greater or equal to day of end.");

    const absences: IPOABS[] = [];

    if (args.isAllDay) {
        const begin = args.dayOfBegin.getStartOfDay();
        const end = args.dayOfEnd.getStartOfDay();
        for (let day = begin; day.getTime() <= end.getTime(); day = day.addDays(1)) {
            absences.push({
                IXS_REF: args.userID,
                ID_UCET: args.accountID,
                ABS: args.accountABS,
                DATUM: day,
                CAS_OD: null,
                CAS_DO: null,
            } as IPOABS);
        }
    } else {
        const isOverMidnight = args.timeOfBegin.getTimeOfDay() > args.timeOfEnd.getTimeOfDay();
        absences.push({
            IXS_REF: args.userID,
            ID_UCET: args.accountID,
            ABS: args.accountABS,
            DATUM: args.dayOfBegin.getStartOfDay(),
            CAS_OD: args.dayOfBegin.withTime(args.timeOfBegin),
            CAS_DO: args.dayOfBegin.addDays(isOverMidnight ? 1 : 0).withTime(args.timeOfEnd),
        } as IPOABS);
    }

    return absences;
}
