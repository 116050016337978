import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";
import { AwCenter, AwNotification, AwSelectedShift, AwShift, AwTime, IAwData } from "../reducers/agreementWorkers";
import { IAccount } from "../../model/plan/types";

export const fetchAwShifts = createAsyncThunk<
    { shifts: AwShift[]; categories: IAccount[] },
    { month: Date; pageIndex: number },
    { state: IRootState }
>("freeshifts/shifts", async function (arg, api) {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
        },
        body: JSON.stringify({
            Item1: arg.month,
            Item2: api.getState().session.freeShifts.checkedCenters,
        }),
        signal: api.signal,
    };

    const response = await fetch(
        api.getState().config.config?.endpoint + "/breeze/IpoData/GetAwShifts",
        requestOptions
    );

    await handleResponseError(response);

    return await response.json();
});

export const fetchAwData = createAsyncThunk<IAwData, void, { state: IRootState }>(
    "freeshifts/data",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/GetAwData",
            requestOptions
        );

        if (response.status === 403) window.location.hash = "";

        await handleResponseError(response);

        return await response.json();
    }
);

export const setAwPreferred = createAsyncThunk<IAwData, AwCenter[], { state: IRootState }>(
    "freeshifts/preferred",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg.map(m => m.ixsStr),
                Item2: arg.map(m => m.preferred),
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetAwPreferred",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const setAwNotifications = createAsyncThunk<IAwData, AwNotification[], { state: IRootState }>(
    "freeshifts/notification",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg.map(m => m.time),
                Item2: arg.map(m => m.enabled),
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetAwNotifications",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const setAwTimes = createAsyncThunk<IAwData, AwTime[], { state: IRootState }>(
    "freeshifts/time",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg.map(m => m.start),
                Item2: arg.map(m => m.end),
                Item3: arg.map(m => m.enabled),
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetAwTimes",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const assignAwShift = createAsyncThunk<{ success: boolean; errors?: string[] }, AwSelectedShift, { state: IRootState }>(
    "freeshifts/assign",
    async function (arg, api) {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg.id,
                Item2: arg.center,
                Item3: arg.day,
                Item4: arg.start,
                Item5: arg.end,
                Item6: arg.category,
                Item7: api.getState().localization.language.code,
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/AssignAwShift",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const scrollAwNext = createAction("freeshifts/next");

export const scrollAwPrevious = createAction("freeshifts/previous");

export const resetAwData = createAction("freeshifts/reset");

export const setAwCheckedCenters = createAction<string[]>("freeshifts/setChecked");
