import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Requests/Requests.module.scss";
import { Input } from "@progress/kendo-react-inputs";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { createUnavailability } from "../../redux/actions/unavailability";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { useAppDispatch } from "../../hooks/useAppDispatch";

const steps = { minute: 30 };

export default function NewUnavailability(props: { onClose?: () => void }) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const ixsRef = useAppSelector(selectIxsRef);
    const creating = useAppSelector(s => s.session.unavailabilityCreate.isFetching);

    const [dateFrom, setDateFrom] = useState(new Date().addDays(1).getStartOfDay());
    const [timeFrom, setTimeFrom] = useState(new Date(0, 0, 0, 0, 0, 0, 0));
    const [timeTo, setTimeTo] = useState(new Date(0, 0, 0, 23, 59, 0, 0));
    const [comment, setComment] = useState("");

    const fromTimeChanged = (time: Date) => {
        setTimeFrom(time);
        if (timeTo < time) {
            setTimeTo(time);
        }
    };

    const toTimeChanged = (time: Date) => {
        setTimeTo(time);
        if (timeFrom > time) {
            setTimeFrom(time);
        }
    };

    const handleSaveButtonClick = () => {
        const unava: PLNUNAVA = {
            IXS_REF: ixsRef,
            DATE_REF: dateFrom,
            NOTE: comment,
            STARTTIME: dateFrom.addTime(timeFrom),
            ENDTIME: dateFrom.addTime(timeTo),
            DATE_CREATE: new Date(),
        };
        dispatch(createUnavailability({ Value: unava })).then(() => props.onClose?.());
    };

    return (
        <Dialog
            title={strings.unavailability.NewUnavailability}
            onClose={props.onClose}
            autoFocus
            width="90%"
            closeIcon={false}
        >
            {creating ? (
                <SpinnerBox />
            ) : (
                <>
                    <div className={styles.datePicker}>
                        <label>{strings.common.Day + ": "}</label>
                        <DatePicker value={dateFrom} onChange={e => e.value && setDateFrom(e.value)} />
                    </div>
                    <div className={styles.datePicker}>
                        <label>{strings.unavailability.TimeFrom + ": "}</label>
                        <TimePicker
                            value={timeFrom}
                            steps={steps}
                            onChange={e => e.value && fromTimeChanged(e.value)}
                        />
                    </div>
                    <div className={styles.datePicker}>
                        <label>{strings.unavailability.TimeTo + ": "}</label>
                        <TimePicker value={timeTo} steps={steps} onChange={e => e.value && toTimeChanged(e.value)} />
                    </div>
                    <Input label={strings.unavailability.NoteComment} onChange={e => setComment(e.value)} />
                </>
            )}
            <DialogActionsBar>
                <Button onClick={props.onClose} disabled={creating}>
                    {strings.common.Cancel}
                </Button>
                <Button onClick={handleSaveButtonClick} disabled={creating}>
                    {strings.common.Save}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
