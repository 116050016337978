import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "./NavbarDayPicker.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { formatDate } from "@telerik/kendo-intl";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function NavbarDayPicker(props: {
    onPick: (day: Date) => void;
    selectedDay: Date;
    labelRenderer?: (day: Date) => React.ReactNode;
}) {
    const language = useAppSelector(s => s.localization.language.code);
    const [isOpen, setIsOpen] = useState(false);
    const [day, setDay] = useState(props.selectedDay);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);
        const day = new Date(event.value.getFullYear(), event.value.getMonth(), event.value.getDate());
        setDay(day);
        props.onPick(day);
    };

    const month = new Date(props.selectedDay.getFullYear(), props.selectedDay.getMonth(), props.selectedDay.getDate());
    return (
        <>
            <Button onClick={() => setIsOpen(true)}>
                {(props.labelRenderer && props.labelRenderer(day)) ?? formatDate(props.selectedDay, "m", language)}
            </Button>
            {isOpen &&
                ReactDOM.createPortal(
                    <Dialog className={styles.dialog} autoFocus onClose={() => setIsOpen(false)}>
                        <Calendar
                            bottomView="month"
                            topView="decade"
                            navigation={false}
                            defaultValue={month}
                            onChange={handleChange}
                        />
                    </Dialog>,
                    document.body
                )}
        </>
    );
}
