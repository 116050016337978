import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { checkLicense, fetchStartup, resetNotification, setCustomerCode } from "../actions/app";
import { DelegatedAuthStyle } from "../../model/app/DelegatedAuthStyle";
import { insertAbsencesToMultipleDates } from "../actions/absenceInsertion";
import { uploadAttachment } from "../actions/worksheet";
import { LOCALSTORAGE_CUSTOMERCODE } from "../../constants/common";
import { assignAwShift } from "../actions/agreementWorkers";
import { insertPass } from "../actions/terminal";

export interface IAppState {
    isFetching: boolean;
    isEnabled: boolean;
    canRunWindowsAuth: boolean;
    isDelegatedAuthEnabled: boolean;
    delegatedAuthStyle: DelegatedAuthStyle;
    delegatedAuthCustomizableText: string;
    secondaryCustomizableText: string;
    error?: SerializedError;
    successResponse?: string;
    errorResponse?: string;
    notificationVisible: boolean;
    notificationData?: string[];
    multiTenant: boolean;
    customerCode: string;
    substitionsEnabled: boolean;
}

export const appReducer = createReducer<IAppState>(
    {
        isFetching: false,
        isEnabled: false,
        canRunWindowsAuth: false,
        isDelegatedAuthEnabled: false,
        delegatedAuthStyle: DelegatedAuthStyle.automaticRedirection,
        delegatedAuthCustomizableText: "",
        secondaryCustomizableText: "",
        notificationVisible: false,
        multiTenant: false,
        customerCode: localStorage.getItem(LOCALSTORAGE_CUSTOMERCODE) ?? "",
        substitionsEnabled: false,
    },
    builder =>
        builder
            .addCase(fetchStartup.pending, (state, action) => ({
                ...state,
                isFetching: true,
                error: undefined,
            }))
            .addCase(fetchStartup.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                error: undefined,
                isEnabled: action.payload.isAppEnabled ?? false,
                canRunWindowsAuth: action.payload.canRunWindowsAuth ?? false,
                isDelegatedAuthEnabled: action.payload.isDelegatedAuthEnabled ?? false,
                delegatedAuthStyle: action.payload.delegatedAuthStyle ?? DelegatedAuthStyle.automaticRedirection,
                delegatedAuthCustomizableText: action.payload.delegatedAuthCustomizableText ?? "",
                secondaryCustomizableText: action.payload.secondaryCustomizableText ?? "",
                multiTenant: action.payload.multiTenant ?? false,
                substitionsEnabled: action.payload.substitutionsEnabled ?? false,
            }))
            .addCase(fetchStartup.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                error: action.error,
                isEnabled: false,
                canRunWindowsAuth: false,
            }))
            .addCase(resetNotification, (state, action) => ({
                ...state,
                notificationVisible: false,
                notificationData: undefined,
                successResponse: undefined,
                errorResponse: undefined,
            }))
            .addCase(insertAbsencesToMultipleDates.fulfilled, (state, action) => {
                if (["over_limit", "date_reserved_unava", "period_locked"].includes(action.payload as string)) {
                    return {
                        ...state,
                        notificationVisible: true,
                        errorResponse: action.payload as string,
                    };
                } else {
                    return state;
                }
            })
            .addCase(uploadAttachment.rejected, (state, action) => ({
                ...state,
                errorResponse: "attachment_upload_error",
                notificationVisible: true,
            }))
            .addCase(setCustomerCode, (state, action) => ({
                ...state,
                customerCode: action.payload,
            }))
            .addCase(checkLicense.fulfilled, (state, action) =>
                !action.payload
                    ? {
                          ...state,
                          errorResponse: "license_error",
                          notificationVisible: true,
                      }
                    : state
            )
            .addCase(assignAwShift.fulfilled, (state, action) => ({
                ...state,
                notificationData: action.payload.errors,
                errorResponse: !action.payload.success
                    ? action.payload.errors
                        ? "err_labor_law"
                        : "free_shift_unavailable"
                    : undefined,
                successResponse: action.payload.success ? "free_shift_assigned" : undefined,
                notificationVisible: true,
            }))
            .addCase(insertPass.fulfilled, (state, action) => ({
                ...state,
                successResponse: "pass_inserted",
                notificationVisible: true,
                notificationData: [action.meta.arg.name],
            }))
            .addCase(insertPass.rejected, (state, action) => ({
                ...state,
                errorResponse: "pass_not_inserted",
                notificationVisible: true,
                notificationData: [action.meta.arg.name],
            }))
);
