import React, { useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { fetchAccounts } from "../redux/actions/dropdowns/accounts";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";

export default function SpecificationsDropdown(props: {
    onSelect: (strav: number) => void;
    selectedStrav: number | undefined;
    specification?: number[];
    disabled?: boolean;
}) {
    const strings = useAppSelector(s => s.localization.strings);
    const loading = useAppSelector(s => s.session.dropdowns.accounts.isFetching);
    const dispatch = useAppDispatch();
    const onSelect = props.onSelect;
    const data = useAppSelector(s => s.session.dropdowns.accounts.specifications);
    const specs = data?.filter(f => props.specification === undefined || props.specification.includes(f.strav));
    const spec = specs?.find(f => f.strav === props.selectedStrav);

    useEffect(() => {
        if (specs === undefined && !loading) {
            dispatch(fetchAccounts());
        }
    }, [dispatch, specs, loading]);

    useEffect(() => {
        if (!spec && specs && specs.length > 0) {
            onSelect(specs[0].strav);
        }
    }, [specs, spec, onSelect]);

    return (
        <React.Fragment>
            {specs?.length === 0 ? (
                <p>{strings.requests.NoAccounts}</p>
            ) : (
                <DropDownList
                    loading={loading}
                    data={specs}
                    textField="nazev"
                    dataItemKey="strav"
                    onChange={e => props.onSelect(e.value.strav)}
                    value={spec}
                    disabled={props.disabled}
                />
            )}
        </React.Fragment>
    );
}
