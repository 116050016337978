import React, { useContext } from "react";
import { IInterval } from "../types";
import { IntervalContextMenuHandlerContext } from "../context/IntervalContextMenuHandlerContext";
import { IntervalClickHandlerContext } from "../context/IntervalClickHandlerContext";
import { GroupContext } from "../context/GroupContext";

interface IProps {
    interval: IInterval;
}

export function Interval(props: IProps) {
    const handleClick = useContext(IntervalClickHandlerContext);
    const handleContextMenu = useContext(IntervalContextMenuHandlerContext);
    const group = useContext(GroupContext);
    return (
        <div
            title={props.interval.title}
            style={{
                overflowX: "hidden",
                whiteSpace: "nowrap",
                border: "1px solid black",
                fontSize: "12px",
                ...props.interval.style,
                cursor: props.interval.deleted ? undefined : "pointer",
                fontStyle: props.interval.deleted ? "italic" : undefined,
            }}
            onClick={e => !props.interval.deleted && handleClick && handleClick(props.interval, group, e)}
            onContextMenu={e =>
                !props.interval.deleted && handleContextMenu && handleContextMenu(props.interval, group, e)
            }
        >
            <span style={{ margin: "0 0.25rem" }}>{props.interval.title}</span>
        </div>
    );
}
