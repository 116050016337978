import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { IAccount, ISpecification } from "../../reducers/dropdowns/accounts";

const DOMAIN = "dropdowns/accounts";

export const fetchAccounts = createAsyncThunk<
    { accounts: IAccount[]; specifications: ISpecification[] },
    undefined,
    { state: IRootState }
>(`${DOMAIN}/fetch`, async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${api.getState().session.user?.token?.access_token}`,
        },
        body: JSON.stringify({
            Value: api.getState().localization.language.code,
        }),
        signal: api.signal,
    };
    const response = await fetch(
        api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetAccountsData",
        requestOptions
    );

    const data = await response.json();

    if (response.status === 200) {
        return {
            accounts: data.accounts.map(
                (account: any) =>
                    ({
                        id: account.ID_UCET,
                        value: account.NAZEV,
                        abs: account.ZKRATKA,
                        strav: account.STRAV,
                        workflow: account.WORKFLOW,
                    } as IAccount)
            ),
            specifications: data.specifications.map(
                (spec: any) =>
                    ({
                        strav: spec.STRAV,
                        nazev: spec.NAZEV,
                    } as ISpecification)
            ),
        };
    } else return api.rejectWithValue(data);
});
