import React, { PropsWithChildren } from "react";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { NavBar } from "../../../components/NavBar/NavBar";
import { connect } from "react-redux";
import { IRootState } from "../../../redux/reducers/root";
import { IStrings } from "../../../constants/languageStrings/IStrings";
import { RouteComponentProps } from "react-router-dom";
import Timeline from "../../../components/Timeline/Timeline";
import moment from "moment";
import {
    fetchMyPlanData,
    fetchMyPlanDataNext,
    fetchMyPlanDataPrevious,
    fetchOldestMonthWithMyPlanRequest,
    resetMyPlanData,
    scrollMyPlanNext,
    scrollMyPlanPrevious,
} from "../../../redux/actions/plan/my";
import { generateGroups } from "./groups";
import NavbarMonthPicker from "../../../components/NavbarMonthPicker/NavbarMonthPicker";
import NewRequestDialog, { ICloseEvent } from "./../../../components/NewRequestDialog/NewRequestDialog";
import { resolveAccounts } from "../shared/resolveAccounts";
import { transformDataForTimeline } from "./transformation";
import { Popup, Offset } from "@progress/kendo-react-popup";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import IntervalDetailDialog from "../shared/IntervalDetailDialog";
import { IPlanTimelineInterval, IAccount, IMyPlanTimelineGroup } from "../../../model/plan/types";
import { IBaseMenuItem } from "../../../components/NavBar/IBaseMenuItem";
import ReactDOM from "react-dom";
import Accounts from "../shared/Accounts";
import NowGroupMarker from "./NowGroupMarker";
import { Button } from "@progress/kendo-react-buttons";
import SigningDialog from "./SigningDialog";
import { SpinnerBox } from "../../../components/Spinner/SpinnerBox";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import classes from "./MyPlan.module.scss";
import { permissions } from "../../../constants/permissions";
import { DayCell } from "./DayCell";
import { useAppSelector } from "../../../hooks/useAppSelector";
import ChangeRequestDialog from "./ChangeRequestDialog";
import { AppDispatch } from "../../..";
import { useStrings } from "../../../hooks/useStrings";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorNotification } from "../../../components/ErrorNotification";
import { IAbortable } from "../../../model/IAbortable";
import { WindowClickListener } from "../../../components/WindowClickListener";
import { SerializedDateTime } from "../../../model/SerializedDateTime";
import { IMyPlanData } from "../../../model/plan/my/IMyPlanData";
import SwipeableViews from "react-swipeable-views";
import { SlideRenderProps, virtualize } from "react-swipeable-views-utils";

const SELECTED_MONTH_KEY = "ANeT/SelfService/plan/my/selectedMonth";

enum EDialog {
    None,
    NewRequest,
    IntervalDetail,
    Signing,
    Change,
}

interface IState {
    selectedMonth: Date;
    paramsDate?: Date;
    visibleDialog: EDialog;
    /** Default values for NewRequest component. */
    defaults?: {
        begin: Date;
        end: Date;
        isAllDay: boolean;
    };
    contextMenu: {
        visible: boolean;
        offset?: Offset;
        isNewRequestItemVisible?: boolean;
    };
    lastClickedInterval?: IPlanTimelineInterval;
    lastSigningRequestId?: number;
    lastApprovingRequestId?: number;
    isSigningWarningVisible: boolean;
    isApprovingWarningVisible: boolean;
    index: number;
}

interface IDispatchProps {
    dispatch: AppDispatch;
    onFetchDataPrevious: (month: Date) => IAbortable;
    onFetchData: (month: Date) => IAbortable;
    onFetchDataNext: (month: Date) => IAbortable;
    onScrollMyPlanPrevious: () => void;
    onScrollMyPlanNext: () => void;
    onResetMyPlanData: () => void;
}

export interface IMyPlanPageProps {
    isLoading: boolean;
    loaded: boolean;
    intervals: IPlanTimelineInterval[];
    publicHolidays: string[];
    accounts: IAccount[];
    signedDays: SerializedDateTime[];
    signableDays: SerializedDateTime[];
    changeableDays: SerializedDateTime[];
    containsUnpublished: boolean;
}

interface IStateProps {
    previousPage: IMyPlanPageProps;
    currentPage: IMyPlanPageProps;
    nextPage: IMyPlanPageProps;
    strings: IStrings;
    signingRequestId?: number;
    approvingRequestId?: number;
    isNewRequestDisabled: boolean;
    isNewRequestVisible: boolean;
}

interface RouteInfo {
    date: string;
}
interface ComponentProps extends RouteComponentProps<RouteInfo> {}

type Props = IStateProps & IDispatchProps & ComponentProps;

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class MyPlan extends React.Component<Props, IState> {
    private signNotifTimeoutID?: number;
    private approveNotifTimeoutID?: number;

    private scrollableRef = React.createRef<HTMLDivElement>();

    private abortable: IAbortable | undefined = undefined;

    constructor(props: Props) {
        super(props);
        const stringDate: string = props.match?.params.date;
        const paramDate: Date | null =
            stringDate != null
                ? new Date(Number(stringDate.substring(0, 4)), Number(stringDate.substring(4, 6)) - 1, 1)
                : null;
        this.state = {
            paramsDate: paramDate != null && !isNaN(paramDate.getTime()) ? paramDate : undefined,
            selectedMonth: paramDate != null && !isNaN(paramDate.getTime()) ? paramDate : new Date(Date.now()),
            visibleDialog: EDialog.None,
            contextMenu: {
                visible: false,
                isNewRequestItemVisible: false,
            },
            isSigningWarningVisible: false,
            isApprovingWarningVisible: false,
            index: 0,
        };
    }

    private createMenuItems = () => {
        const strings = this.props.strings;
        const items: IBaseMenuItem[] = [];

        if (this.props.isNewRequestVisible)
            items.unshift({
                id: 1,
                content: strings.requests.NewRequest,
                disabled: this.props.isNewRequestDisabled,
            });

        return items;
    };

    private createContextMenuItems = () => {
        const strings = this.props.strings;
        const items = [{ id: 1, text: strings.plan.DetailPlan, disabled: false }];

        if (this.state.contextMenu.isNewRequestItemVisible && this.props.isNewRequestVisible)
            items.push({
                id: 2,
                text: strings.requests.NewRequest,
                disabled: this.props.isNewRequestDisabled,
            });

        return items;
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                this.setState({
                    defaults: undefined, // clear
                    visibleDialog: EDialog.NewRequest,
                });
                break;
            default:
                item.id && console.warn("Unhandled value:", item.id);
        }
    };

    private handleMonthChange = (month: Date) => {
        if (this.state.selectedMonth.getTime() !== month.getTime()) {
            this.setState({ selectedMonth: month });
            this.props.onResetMyPlanData();
        }
    };

    private handleNewRequestDialogClose = (e: ICloseEvent) => {
        this.setState({
            visibleDialog: EDialog.None,
        });

        if (!e.canceled && e.saved) this.abortable = this.props.onFetchData(this.state.selectedMonth);
    };

    private handleTimelineItemClick = (interval: IPlanTimelineInterval) => {
        this.setState({
            visibleDialog: EDialog.IntervalDetail,
            lastClickedInterval: interval,
        });
    };

    private handleTimelineItemContextMenu = (
        interval: IPlanTimelineInterval,
        group: IMyPlanTimelineGroup,
        e: React.MouseEvent
    ) => {
        e.preventDefault();

        const shift = interval.parent.type === "IPODENSMEN" ? interval.parent : null;

        this.setState({
            defaults: shift
                ? {
                      begin: shift.begin,
                      end: shift.end,
                      isAllDay: false,
                  }
                : undefined,
            contextMenu: {
                visible: true,
                offset: {
                    left: e.clientX,
                    top: e.clientY,
                },
                isNewRequestItemVisible: shift != null && shift.day.getTime() >= new Date().getTime(),
            },
            lastClickedInterval: interval,
        });
    };

    private handleContextMenuSelect = (e: MenuSelectEvent) => {
        switch (e.item.data) {
            case 1:
                this.setState({
                    visibleDialog: EDialog.IntervalDetail,
                });
                break;
            case 2:
                this.setState({
                    visibleDialog: EDialog.NewRequest,
                });
                break;
            default:
                console.warn("Unhandled value:", e.item.data);
        }
    };

    private handleWindowClick = () => {
        this.setState({
            contextMenu: {
                visible: false,
            },
        });
    };

    private handleDetailDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleDetailDialogCancel = () => {
        this.props.onFetchData(this.state.selectedMonth);
        this.handleDetailDialogClose();
    };

    private handleSigningDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleSigningDialogSigned = () => {
        this.setState({
            visibleDialog: EDialog.None,
            isSigningWarningVisible: false,
        });

        const promise = this.props.dispatch(fetchOldestMonthWithMyPlanRequest());
        this.abortable = promise;
        promise.then(unwrapResult).then(result => {
            const month = result ? new Date(result) : null;
            if (month) {
                this.setState({ selectedMonth: month });
                this.props.onFetchData(month);
            } else this.abortable = this.props.onFetchData(this.state.selectedMonth);
        });
    };

    private handleChangeRequestDialogUpdated = () => {
        this.setState({ visibleDialog: EDialog.None });

        const promise = this.props.dispatch(fetchOldestMonthWithMyPlanRequest());
        this.abortable = promise;
        promise.then(unwrapResult).then(result => {
            const month = result ? new Date(result) : null;
            if (month) {
                this.setState({ selectedMonth: month });
                this.props.onFetchData(month);
            } else this.abortable = this.props.onFetchData(this.state.selectedMonth);
        });
    };

    private handleSigningWarningClose = () => {
        this.setState({ isSigningWarningVisible: false });
    };

    private handleApprovingingWarningClose = () => {
        this.setState({ isApprovingWarningVisible: false });
    };

    private scrollToLine(line: number) {
        const HEADER_HEIGHT = 23;
        const LINE_HEIGHT = 49;

        this.scrollableRef.current?.scroll({
            top: HEADER_HEIGHT + LINE_HEIGHT * line,
        });
    }

    async componentDidMount() {
        this.props.onResetMyPlanData();
        const promise = this.props.dispatch(fetchOldestMonthWithMyPlanRequest());
        this.abortable = promise;
        const result = await promise.then(unwrapResult);
        let month = result ? new Date(result) : null;

        if (month === null) {
            const timestamp = Date.parse(localStorage.getItem(SELECTED_MONTH_KEY) ?? "");
            month = new Date(!isNaN(timestamp) ? timestamp : Date.now());
        }
        if (this.state.paramsDate != null) {
            month = this.state.paramsDate;
        }

        this.setState({ selectedMonth: month });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>) {
        if (
            this.state.selectedMonth.getTime() !== prevState.selectedMonth.getTime() ||
            this.state.selectedMonth.getTime() === this.state.paramsDate?.getTime()
        ) {
            this.setState({ paramsDate: undefined });
            localStorage.setItem(SELECTED_MONTH_KEY, this.state.selectedMonth.toISOStringIgnoringTZ());
            if (!this.props.currentPage.loaded) {
                this.abortable = this.props.onFetchData(this.state.selectedMonth);
            }
        }

        if (this.props.signingRequestId && this.props.signingRequestId !== this.state.lastSigningRequestId) {
            this.setState(
                (prevState, props) =>
                    ({
                        lastSigningRequestId: props.signingRequestId,
                        isSigningWarningVisible: true,
                    }) as IState
            );
            this.signNotifTimeoutID = setTimeout(
                () => this.setState({ isSigningWarningVisible: false }),
                5000
            ) as unknown as number;
        } else if (
            !this.state.isSigningWarningVisible &&
            this.props.approvingRequestId &&
            this.props.approvingRequestId !== this.state.lastApprovingRequestId
        ) {
            this.setState(
                (prevState, props) =>
                    ({
                        lastApprovingRequestId: props.approvingRequestId,
                        isApprovingWarningVisible: true,
                    }) as IState
            );
            this.approveNotifTimeoutID = setTimeout(
                () => this.setState({ isApprovingWarningVisible: false }),
                5000
            ) as unknown as number;
        }

        if (!this.state.isSigningWarningVisible && prevState.isSigningWarningVisible) {
            clearTimeout(this.signNotifTimeoutID);
        }

        if (!this.state.isApprovingWarningVisible && prevState.isApprovingWarningVisible) {
            clearTimeout(this.approveNotifTimeoutID);
        }

        const now = new Date();
        if (
            prevProps.currentPage.isLoading !== this.props.currentPage.isLoading &&
            now.getFullYear() === this.state.selectedMonth.getFullYear() &&
            now.getMonth() === this.state.selectedMonth.getMonth()
        ) {
            this.scrollToLine(now.getDate() - 2);
        }

        if (this.props.currentPage.loaded && !this.props.currentPage.isLoading) {
            if (!this.props.nextPage.loaded && !this.props.nextPage.isLoading) {
                this.props.onFetchDataNext(this.state.selectedMonth);
            } else if (!this.props.previousPage.loaded && !this.props.previousPage.isLoading) {
                this.props.onFetchDataPrevious(this.state.selectedMonth);
            }
        }
    }

    componentWillUnmount() {
        this.abortable?.abort();
        clearTimeout(this.signNotifTimeoutID);
        clearTimeout(this.approveNotifTimeoutID);
    }

    pageLayout(dataSource: IMyPlanPageProps, monthOffset?: 1 | undefined | -1) {
        const selectedMonth = this.state.selectedMonth.addMonths(monthOffset ?? 0);
        return (
            <>
                <Loading isLoading={dataSource.isLoading}>
                    <ErrorGuard>
                        <Timeline
                            groups={generateGroups(selectedMonth)}
                            stacks={[
                                { id: 0, visibleIfEmpty: true },
                                { id: 1, visibleIfEmpty: false },
                                { id: 2, visibleIfEmpty: true },
                                { id: 3, visibleIfEmpty: false },
                                { id: 4, visibleIfEmpty: false },
                                { id: 5, visibleIfEmpty: false },
                            ]}
                            intervals={dataSource.intervals}
                            columns={[
                                {
                                    id: selectedMonth.getTime(),
                                    beginDay: moment(selectedMonth).startOf("month").toDate(),
                                    endDay: moment(selectedMonth).endOf("month").toDate(),
                                },
                            ]}
                            onIntervalClick={this.handleTimelineItemClick}
                            onIntervalContextMenu={this.handleTimelineItemContextMenu}
                            cornerCellContentRenderer={() => (
                                <div
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <span>{this.props.strings.common.Day}</span>
                                </div>
                            )}
                            dayHeaderContentRenderer={() => null}
                            groupSidebarCellContentRenderer={props => (
                                <DayCell day={props.group.day} page={dataSource} />
                            )}
                            cellMarkerRenderer={props => <NowGroupMarker {...props} />}
                        />
                        {dataSource.containsUnpublished && (
                            <span className={classes.noteRow}>
                                <span className="k-icon k-i-info" />
                                {this.props.strings.plan.ContainsUnpublished}
                            </span>
                        )}
                        <div className="section-container">
                            <Accounts accounts={dataSource.accounts} />
                        </div>
                    </ErrorGuard>
                </Loading>
            </>
        );
    }

    private slideRenderer = (props: SlideRenderProps) => {
        if (props.index < this.state.index)
            return <div key={props.key}>{this.pageLayout(this.props.previousPage, -1)}</div>;
        else if (props.index > this.state.index)
            return <div key={props.key}>{this.pageLayout(this.props.nextPage, 1)}</div>;
        else return <div key={props.key}>{this.pageLayout(this.props.currentPage)}</div>;
    };

    private scrollPrevious = () => {
        this.props.onScrollMyPlanPrevious();
        const newMonth = this.state.selectedMonth.addMonths(-1);
        this.setState({ selectedMonth: newMonth });
        this.props.onFetchDataPrevious(newMonth);
    };

    private scrollNext = () => {
        this.props.onScrollMyPlanNext();
        const newMonth = this.state.selectedMonth.addMonths(1);
        this.setState({ selectedMonth: newMonth });
        this.props.onFetchDataNext(newMonth);
    };

    public render() {
        return (
            <PageLayout
                canScrollPrevious={!this.props.previousPage.isLoading && this.props.previousPage.loaded}
                canScrollNext={!this.props.nextPage.isLoading && this.props.nextPage.loaded}
                onScrollPrevious={this.scrollPrevious}
                onScrollNext={this.scrollNext}
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.dashboard.MyPlan}
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <NavbarMonthPicker selectedMonth={this.state.selectedMonth} onPick={this.handleMonthChange} />
                        <SignButton onClick={() => this.setState({ visibleDialog: EDialog.Signing })} />
                        <ChangeButton onClick={() => this.setState({ visibleDialog: EDialog.Change })} />
                    </NavBar>
                }
            >
                <VirtualizeSwipeableViews
                    style={{ minHeight: "100%" }}
                    overscanSlideBefore={1}
                    overscanSlideAfter={1}
                    slideRenderer={this.slideRenderer}
                    onChangeIndex={(index1, index0) => {
                        this.setState({ index: index1 });
                        if (index0 < index1) {
                            this.scrollNext();
                        } else {
                            this.scrollPrevious();
                        }
                    }}
                />
                {ReactDOM.createPortal(
                    <React.Fragment>
                        {this.state.visibleDialog === EDialog.NewRequest && (
                            <NewRequestDialog
                                isRequest={true}
                                canSelectPerson={false}
                                onlyWorkflow={false}
                                onClose={this.handleNewRequestDialogClose}
                                defaults={this.state.defaults}
                            />
                        )}
                        <WindowClickListener onClick={() => this.handleWindowClick()}>
                            <Popup
                                show={this.state.contextMenu.visible}
                                offset={this.state.contextMenu.offset}
                                animate={false}
                            >
                                <Menu vertical={true} onSelect={this.handleContextMenuSelect}>
                                    {this.createContextMenuItems().map(item => (
                                        <MenuItem
                                            key={item.id}
                                            data={item.id}
                                            text={item.text}
                                            disabled={item.disabled}
                                        />
                                    ))}
                                </Menu>
                            </Popup>
                        </WindowClickListener>
                        {this.state.visibleDialog === EDialog.IntervalDetail && (
                            <IntervalDetailDialog
                                interval={this.state.lastClickedInterval!}
                                onClose={this.handleDetailDialogClose}
                                onCancel={this.handleDetailDialogCancel}
                            />
                        )}
                        {this.state.visibleDialog === EDialog.Signing && (
                            <SigningDialog
                                onClose={this.handleSigningDialogClose}
                                onSigned={this.handleSigningDialogSigned}
                            />
                        )}
                        {this.state.visibleDialog === EDialog.Change && (
                            <ChangeRequestDialog
                                onClose={() => this.setState({ visibleDialog: EDialog.None })}
                                onUpdated={this.handleChangeRequestDialogUpdated}
                            />
                        )}
                        <NotificationGroup
                            style={{
                                position: "absolute",
                                top: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 9999,
                            }}
                        >
                            <Slide>
                                {this.state.isSigningWarningVisible && (
                                    <Notification
                                        type={{ icon: false, style: "warning" }}
                                        closable
                                        onClose={this.handleSigningWarningClose}
                                    >
                                        {this.props.strings.plan.Msg_ConfirmAcquaintWithYourPlanPlease}
                                    </Notification>
                                )}
                            </Slide>
                            <Slide>
                                {this.state.isApprovingWarningVisible && !this.state.isSigningWarningVisible && (
                                    <Notification
                                        type={{ icon: false, style: "error" }}
                                        closable
                                        onClose={this.handleApprovingingWarningClose}
                                    >
                                        {this.props.strings.plan.Msg_ApprovePlanChanges}
                                    </Notification>
                                )}
                            </Slide>
                        </NotificationGroup>
                    </React.Fragment>,
                    document.body
                )}
            </PageLayout>
        );
    }
}

function getPageState(page?: IMyPlanData) {
    const intervals = [
        ...(page?.intervals ?? []),
        ...(page?.changeRequests.map(r => r.intervals).reduce((prev, curr) => [...prev, ...curr], []) ?? []),
    ];
    return {
        intervals: transformDataForTimeline(intervals),
        accounts: resolveAccounts(intervals),
        publicHolidays: page?.publicHolidays ?? [],
        signedDays: page?.signedDays ?? [],
        signableDays: page?.signableDays ?? [],
        changeableDays: page?.changeableDays ?? [],
        containsUnpublished: page?.containsUnpublished ?? false,
    };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => {
        const sigReqs = state.session.user.token?.userDisabledActions.some(p => p === permissions.plan.check)
            ? []
            : state.session.plan.my.currentPage?.signingRequests ?? [];
        const apprReqs = state.session.user.token?.userDisabledActions.some(p => p === permissions.plan.approve)
            ? []
            : state.session.plan.my.currentPage?.changeRequests ?? [];
        const token = state.session.user.token;

        const props: IStateProps = {
            previousPage: {
                ...getPageState(state.session.plan.my.previousPage),
                isLoading: state.session.plan.my.isFetchingPrevious,
                loaded: !!state.session.plan.my.previousPage,
            },
            currentPage: {
                ...getPageState(state.session.plan.my.currentPage),
                isLoading: state.session.plan.my.isFetching,
                loaded: !!state.session.plan.my.currentPage,
            },
            nextPage: {
                ...getPageState(state.session.plan.my.nextPage),
                isLoading: state.session.plan.my.isFetchingNext,
                loaded: !!state.session.plan.my.nextPage,
            },
            strings: state.localization.strings,
            signingRequestId: sigReqs.length ? sigReqs[0].id : undefined,
            approvingRequestId: apprReqs.length ? apprReqs[0].id : undefined,
            isNewRequestVisible: token?.userInvisibleActions.every(p => p !== permissions.plan.absence) ?? false,
            isNewRequestDisabled: token?.userDisabledActions.some(p => p === permissions.plan.absence) ?? true,
        };

        return props;
    },
    (dispatch: AppDispatch) => ({
        dispatch: dispatch,
        onFetchDataPrevious: month => dispatch(fetchMyPlanDataPrevious({ month: month })),
        onFetchData: month => dispatch(fetchMyPlanData({ month: month })),
        onFetchDataNext: month => dispatch(fetchMyPlanDataNext({ month: month })),
        onScrollMyPlanPrevious: () => dispatch(scrollMyPlanPrevious()),
        onScrollMyPlanNext: () => dispatch(scrollMyPlanNext()),
        onResetMyPlanData: () => dispatch(resetMyPlanData()),
    })
)(MyPlan);

function SignButton(props: { onClick?: () => void }) {
    const strings = useStrings();
    const canSign = useAppSelector(s => (s.session.plan.my.currentPage?.signingRequests.length ?? 0) > 0);
    const isCheckAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.check)) ??
        false;

    return !canSign || !isCheckAllowed ? null : (
        <Button
            className={classes.signButton}
            title={strings.plan.AcquaintanceWithPlan}
            icon="check"
            onClick={props.onClick}
        />
    );
}

function ChangeButton(props: { onClick?: () => void }) {
    const canChange = useAppSelector(s => (s.session.plan.my.currentPage?.changeRequests.length ?? 0) > 0);
    const isChangeAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.approve)) ??
        false;
    const strings = useStrings();

    return !canChange || !isChangeAllowed ? null : (
        <Button className={classes.changeButton} title={strings.plan.PlanChange} icon="check" onClick={props.onClick} />
    );
}

function Loading(props: PropsWithChildren<{ isLoading: boolean }>) {
    if (props.isLoading) {
        return <SpinnerBox stretchToWindow />;
    }

    return <>{props.children}</>;
}

function ErrorGuard(props: PropsWithChildren<{}>) {
    const error = useAppSelector(s => s.session.plan.my.error);

    if (error) {
        return <ErrorNotification error={error} />;
    }

    return <>{props.children}</>;
}
