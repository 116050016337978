import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect } from "react";
import classes from "./CategoriesPicker.module.scss";
import { fetchPossibleCategories } from "../../redux/actions/dropdowns/categories";
import { IPOREFKAT } from "../../redux/reducers/dropdowns/categories";
import { resetCategory } from "../../redux/actions/centerPicker";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export default function CategoriesPicker(props: { onSelect: (category: IPOREFKAT) => void }) {
    const strings = useAppSelector(s => s.localization.strings);
    const categories = useAppSelector(s => s.session.categories.categories);
    const isFetching = useAppSelector(s => s.session.categories.isFetching);
    const defaultItem: IPOREFKAT = { NAZEV_KAT: strings.common.AllCatsLabel, IXS_KAT: 0 };
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetCategory());
    }, [dispatch]);

    return (
        <section>
            <div className={classes.root}>
                <label className={classes.description}>{strings.common.Category}:</label>
                <div className={classes.dropdown}>
                    <DropDownList
                        data={categories}
                        textField="NAZEV_KAT"
                        dataItemKey="IXS_KAT"
                        defaultItem={defaultItem}
                        loading={isFetching}
                        onChange={e => props.onSelect(e.value)}
                        onOpen={() => dispatch(fetchPossibleCategories())}
                    />
                </div>
            </div>
        </section>
    );
}
