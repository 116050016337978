import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ISubstitutuinState } from "../reducers/substitutions";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";

export const fetchSubstitutions = createAsyncThunk<Partial<ISubstitutuinState>, void, { state: IRootState }>(
    "substitution/fetchSubstitutions",
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/GetSubstitutions",
            requestOptions
        );

        await handleResponseError(response);

        let result = await response.json();
        return result;
    }
);

export const editSubstitution = createAction<{
    id: number;
    ixsref: string;
    start: string;
    end: string;
    actions: string[];
}>("substitution/editSubstitution");

export const createSubstitution = createAction<{
    ixsref: string;
    start: string;
    end: string;
    actions: string[];
}>("substitution/createSubstitution");

export const toggleSubstitution = createAction<number>("substitution/toggleSubstitution");
