import React, { useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { fetchAccounts } from "../redux/actions/dropdowns/accounts";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";

export default function AccountDropdown(props: {
    onSelect: (accountId: number) => void;
    selectedAccountId: number | undefined;
    onlyHolidayAccounts?: boolean;
    onlyWorkflowAccounts?: boolean;
    disabled?: boolean;
}) {
    const strings = useAppSelector(s => s.localization.strings);
    const loading = useAppSelector(s => s.session.dropdowns.accounts.isFetching);
    const dispatch = useAppDispatch();
    const onSelect = props.onSelect;
    const onlyHolidayAccounts = props.onlyHolidayAccounts;
    const onlyWorkflowAccounts = props.onlyWorkflowAccounts;
    const payload = useAppSelector(s => s.session.dropdowns.accounts.accounts);
    const accounts = payload?.filter(
        f =>
            (!onlyHolidayAccounts || f.strav === 1 || f.strav === 2 || f.strav === 10) &&
            (!onlyWorkflowAccounts || f.workflow === 1)
    );
    const account = accounts?.find(f => f.id === props.selectedAccountId);

    useEffect(() => {
        if (accounts === undefined && !loading) {
            dispatch(fetchAccounts());
        }
    }, [dispatch, accounts, loading]);

    useEffect(() => {
        if (!account && accounts && accounts.length > 0) {
            onSelect(accounts[0].id);
        }
    }, [accounts, account, onSelect]);

    return (
        <React.Fragment>
            {accounts?.length === 0 ? (
                <p>{strings.requests.NoAccounts}</p>
            ) : (
                <DropDownList
                    loading={loading}
                    data={accounts}
                    textField="value"
                    dataItemKey="id"
                    onChange={e => props.onSelect(e.value.id)}
                    value={account}
                    disabled={props.disabled}
                />
            )}
        </React.Fragment>
    );
}
