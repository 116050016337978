import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { AwShift } from "../../redux/reducers/agreementWorkers";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Error } from "@progress/kendo-react-labels";
import styles from "./AwSelectionDialog.module.scss";
import { formatString } from "../../utils/LocalizationUtils";
import { dayDiff } from "../../utils/DateUtils";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { assignAwShift, resetAwData } from "../../redux/actions/agreementWorkers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { formatDate } from "@telerik/kendo-intl";

function timeDiffExcess(
    defaultStart: Date,
    defaultEnd: Date,
    start: Date,
    end: Date,
    diff?: { min: number; max: number }
) {
    const startDiff = start.getTime() - defaultStart.getTime();
    const endDiff = end.getTime() - defaultEnd.getTime();
    const totalDiff = (endDiff - startDiff) / (1000 * 60);
    if (totalDiff > (diff?.min ?? 0)) return totalDiff;
    if (totalDiff < -(diff?.max ?? 0)) return totalDiff;
    return 0;
}

function getTimes(refDate: Date, time: Date, min: Date, max: Date, step: number, locale: string) {
    let d = new Date(time.getTime());
    let times = [time];
    while (((d = d.addMinutes(-step)), d >= min)) times = [new Date(d.getTime()), ...times];
    d = new Date(time.getTime());
    while (((d = d.addMinutes(step)), d <= max)) times = [...times, new Date(d.getTime())];
    return times.map(m => {
        const days = dayDiff(refDate, m);
        return {
            time: m,
            text: formatDate(m, "t", locale) + (days > 0 ? ` (+${days})` : days < 0 ? ` (${days})` : ""),
        };
    });
}

export default function AwSelectionDialog(props: { onClose?: () => void; shift?: AwShift }) {
    const strings = useStrings();
    const dispatch = useAppDispatch();
    const [start, setStart] = useState(new Date(props.shift?.start.default ?? ""));
    const [end, setEnd] = useState(new Date(props.shift?.end.default ?? ""));
    const category = useAppSelector(s => s.session.freeShifts.data[1]?.categories)?.find(
        f => f.id === props.shift?.category
    );

    const locale = useAppSelector(s => s.localization.language.code);

    const [loading, setLoading] = useState(false);

    const day = new Date(props.shift?.day ?? "");
    const defaultStart = new Date(props.shift?.start.default ?? "");
    const defaultEnd = new Date(props.shift?.end.default ?? "");
    const minStart = new Date(props.shift?.start.min ?? props.shift?.start.default ?? "");
    const maxStart = new Date(props.shift?.start.max ?? props.shift?.start.default ?? "");
    const minEnd = new Date(props.shift?.end.min ?? props.shift?.end.default ?? "");
    const maxEnd = new Date(props.shift?.end.max ?? props.shift?.end.default ?? "");

    const step = useAppSelector(s => s.session.freeShifts.step);
    const startTimes = getTimes(day, defaultStart, minStart, maxStart, step, locale);
    const endTimes = getTimes(day, defaultEnd, minEnd, maxEnd, step, locale);

    const onConfirm = async () => {
        if (props.shift) {
            setLoading(true);
            await dispatch(
                assignAwShift({
                    id: props.shift.id,
                    center: props.shift.center.id,
                    day: day,
                    start: start,
                    end: end,
                    category: props.shift.category,
                })
            );
            setLoading(false);
            dispatch(resetAwData());
            props.onClose?.();
        }
    };

    const diffExcess = timeDiffExcess(defaultStart, defaultEnd, start, end, props.shift?.diff);
    const startValid = start >= minStart && start <= maxStart;
    const endValid = end >= minEnd && end <= maxEnd;

    const startDisabled =
        loading ||
        (!props.shift?.start.min && !props.shift?.start.max) ||
        (props.shift.start.min === props.shift.start.default && props.shift.start.max === props.shift.start.default);

    const endDisabled =
        loading ||
        (!props.shift?.end.min && !props.shift?.end.max) ||
        (props.shift.end.min === props.shift.end.default && props.shift.end.max === props.shift.end.default);

    return (
        <Dialog title={strings.freeShifts.Selection} onClose={props.onClose} closeIcon={false}>
            <div className={styles.list}>
                <div>
                    <label>{strings.freeShifts.Date}</label>
                    <DatePicker value={day} disabled />
                </div>
                <div>
                    <label>{strings.freeShifts.Center}</label>
                    <Input value={props.shift?.center.name.trim()} disabled />
                </div>
                <div>
                    <label>{strings.freeShifts.Start}</label>
                    <DropDownList
                        value={startTimes.find(f => f.time.getTime() === start.getTime())}
                        onChange={e => setStart(e.value.time)}
                        data={startTimes}
                        textField="text"
                        disabled={startDisabled}
                    />
                </div>
                <div>
                    <label>{strings.freeShifts.End}</label>
                    <DropDownList
                        value={endTimes.find(f => f.time.getTime() === end.getTime())}
                        onChange={e => setEnd(e.value.time)}
                        data={endTimes}
                        textField="text"
                        disabled={endDisabled}
                    />
                </div>
                {category && category.id !== 0 && (
                    <div>
                        <label>{strings.freeShifts.Category}</label>
                        <Input value={category.name} disabled />
                    </div>
                )}
                <Error className={styles.error}>
                    {startValid && endValid && (
                        <>
                            {diffExcess > 0 &&
                                formatString(strings.freeShifts.ErrorMin, props.shift?.diff.min ?? 0, diffExcess)}
                            {diffExcess < 0 &&
                                formatString(strings.freeShifts.ErrorMax, props.shift?.diff.max ?? 0, -diffExcess)}
                        </>
                    )}
                </Error>
            </div>
            <DialogActionsBar>
                <Button onClick={() => props.onClose?.()} disabled={loading}>
                    {strings.common.Close}
                </Button>
                <Button
                    onClick={onConfirm}
                    disabled={loading || !(startValid && endValid && diffExcess === 0)}
                    icon={loading ? "loading" : ""}
                >
                    {loading ? "" : strings.common.Confirm}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
