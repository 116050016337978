export function initializeGoogleAnalytics(id: string) {
    const script = document.createElement("script");
    script.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${id}`);
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
        window.dataLayer.push(arguments);
    };

    window.gtag("js", new Date());
    window.gtag("config", id);
}

export function gtag(...args: any[]) {
    window.gtag?.(...args);
}
