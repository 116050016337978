export const sk = {
    common: {
        AllCatsLabel: "<Všetky>",
        Category: "Kategórie",
        Begin: "Začiatok",
        Cancel: "Zrušiť",
        Center: "Stredisko",
        Close: "Zavrieť",
        Confirm: "Potvrdiť",
        Day: "Deň",
        Delete: "Zmazať",
        DeleteAll: "Zmazať všetko",
        End: "Koniec",
        ErrorNotification: "Došlo k neočakávanej chybe.",
        ErrorNotificationClient: "Dáta nie sú dostupné, zkontrolujte připojení.",
        ErrorNotificationServer: "Došlo k chybe na serveri, kontaktujte správce.",
        For: "Pre",
        Person: "Osoba",
        Change: "Zmeniť",
        Help: "Nápoveda",
        Label: "Popisok",
        Language: "Jazyk",
        NameNote: "Názov",
        No: "Nie",
        NoItems: "Žiadne položky",
        OC: "Osobné číslo",
        Save: "Uložiť",
        Shortcut: "Skratka",
        Yes: "Áno",
        Approve: "Schváliť",
        Deny: "Zamietnuť",
        continue: "Pokračovať",
    },
    app: {
        ConfigurationDownloading: "Sťahovanie konfigurácie",
        LicenseChecking: "Kontrola licencie",
        ServerChecking: "Kontrola servera",
        Msg_AppDisabledByLicense: "Použitie aplikácie je zakázané licenciou.",
        PageNotFound: "Stránka nenájdená.",
        PageNotFoundTitle: "404",
    },
    dashboard: {
        Balance: "Saldo",
        BalanceError: "Chyba salda",
        VacationBalance: "Zostatok dovolenky",
        EmailForNotifications: "Email pre notifikácie",
        DepartmentPlan: "Plán oddelenia",
        FreeShifts: "Voľné zmeny",
        Logout: "Odhlásiť",
        MyAccount: "Môj účet",
        MyPlan: "Môj plán",
        MyRequests: "Moje požiadavky",
        MyUnavailabilities: "Moja nedostupnosť",
        OnlineTerminal: "Terminál",
        MyWorksheet: "Môj pracovný list",
        Reports: "Reporty",
        RequestsToApprove: "Požiadavky na schválenie",
        RequestsToApprovePlnunava: "Schvaľovanie nedostupností",
        PersonalCard: "Karta osoby",
        PlansStates: "Stavy plánov",
        Presence: "Prítomnosť",
        WorkSheetStates: "Stavy pracovných listov",
        NotComputed: "Nespočítané",
        Days: "Dní",
        Hours: "Hodiny",
        Hours4: "Hodín",
        AdditionalVacation: "Zostatok dodatkovej dovolenky I",
        AdditionalVacation2: "Zostatok dodatkovej dovolenky II",
        Overtime: "Moje nadčasy",
    },
    lastPasses: {
        TitleLP: "Priechody",
        NoPasses: "Žiadne priechody...",
    },
    login: {
        Username: "Užívateľské meno",
        Password: "Heslo",
        Remember: "Zapamätať údaje",
        Login: "Prihlásiť",
        LoginDialog: "Prihlasovací dialóg",
        CustomerCode: "Kód zákazníka",
        CustomerCodeChange: "Zmeniť kód zákazníka",
        login_error_user_not_registered: "Chyba prihlásenia: Užívateľ nemá založený účet pre WebTime.",
        login_error_invalid_grant: "Neplatné užívateľské meno alebo heslo.",
        login_error_invalid_grant_multitenant: "Neplatné uživateľské meno, heslo alebo kód zákazníka.",
        login_error_locked_account: "Účet dočasne zablokovaný na {0} minút.",
        login_error_app_usage_not_allowed: "Neplatné užívateľské meno alebo heslo.",
        login_error_user_expired: "Osoba mimo platnosť.",
        //login_error_app_usage_not_allowed: 'Nemáte povolené používať túto aplikáciu.',
    },
    passwordChange: {
        title: "Zmena hesla",
        oldPassword: "Pôvodné heslo",
        newPassword: "Nové heslo",
        newPasswordVerification: "Potvrdenie nového hesla",
        error_not_same: "Nové heslá sa nezhodujú!",
        error_contains_username: "Heslo nesmie obsahovať užívateľské meno",
        error_contains_3_chars: "Heslo nesmie obsahovať sekvenciu 3 opakujúcich sa znakov",
        error_contains_sequence: "Heslo nesmie obsahovať stúpajúci alebo klesajúci postupnosť znakov",
        error_password_in_history: "Nové heslo je identické s niektorým heslom v histórii!",
        error_invalid_old_password: "Pôvodné heslo nie je platné!",
    },
    reports: {
        Params: "Zadajte parametre reportu",
        LoadReport: "Načítať",
    },
    requests: {
        Approve: "Schváliť",
        Approved: "Schválený",
        ApprovedBy: "Schválil",
        CancelRequest: "Zrušiť požiadavku",
        Canceled: "Zrušený",
        Comment: "Poznámka",
        CreatedReq: "Vytvorený",
        DaysUpTo4: "dni",
        DefaultAccount: "Vyberte účet",
        ErrorRequiredNote: "Pre zamietnutia je potreba zapísať poznámku",
        ErrorRequiredNoteUnava: "Na zadanie požiadavky je potrebná poznámka.",
        Expired: "Po termíne",
        ForPerson: "Pre osobu",
        FromDate: "Od",
        Fullday: "Celodenné",
        IsWrongDayOrder: "Deň konca nemôže byť pred dňom začiatku!",
        NewReq: "Nový",
        NewRequest: "Nová požiadavka",
        NewRequestFor: "Nová požiadavka pre ...",
        NoData: "Žiadne požiadavky ...",
        NoAccounts: "Žiadne účty pre plánovanie požiadaviek",
        Pending: "Čakajúci",
        Reject: "Zamietnuť",
        Rejected: "Zamietnutý",
        RejectedElectronically: "Zamietnutý elektronicky",
        RejectedHand: "Zamietnutý ručně",
        RejectedBy: "Zamietol",
        RemainingDay: "Do konca zoznámenie zostáva 1 deň",
        RemainingDays: "Do konca zoznámenie zostáva $0$ dní",
        RemainingDaysUpTo4: "Do konca zoznámenie zostávajú $0$ dni",
        RequestSingle: "Požiadavka",
        ReqForApproval: "Požiadavky",
        Search: "Hľadať ...",
        ShowOnly: "Len čakajúce požiadavky",
        StateName: "Stav",
        ToDate: "Do",
        Type: "Typ",
        SelectAll: "Vybrať všetky",
        ApproveSelected: "Schváliť vybrané požiadavky?",
        RejectSelected: "Zamietnuť vybrané požiadavky?",
        MassApprove: "Hromadné schvaľovanie",
    },
    terminals: {
        GPSText: "na súradniciach",
        ChoosePass: "Vyberte priechod",
        Settings: "Nastavenie",
        TitleTerminals: "Terminál",
        NoPass: "<Žiadny priechod>",
        Locating: "Zisťovanie polohy",
        LocatingDone: "Poloha zistená",
        LocatingFailed: "Poloha nezistená",
    },
    personalCard: {
        EmploymentNumber: "Číslo prac. pomeru",
        Phone: "Telefón",
        Profession: "Profesia",
        ValidFrom: "Platný od",
        ValidTo: "Platný do",
        LastPassage: "Posledný priechod",
    },
    unavailability: {
        CreatedUnavailability: "Vytvorené",
        New: "Nová",
        NewUnavailability: "Nová nedostupnosť",
        NoteComment: "Poznámka",
        TimeFrom: "Čas od",
        TimeTo: "Čas do",
        Unavailability: "Moje nedostupnosti",
        Unavailabilities: "Nedostupnosti",
    },
    worksheet: {
        Absence: "Abs.",
        AccountDefName: "Účet",
        ApprovedWS: "Schválené",
        BalanceNext: "Saldo v budúcom období",
        CalendarFund: "Fond podľa kalendára dohodnutý/stanovený",
        ComputeTo: "Počítať do",
        Confirmed: "Potvrdené",
        DaysWS: "Dni",
        ErrorWS: "Chyba",
        Model: "Model",
        MonthAccounts: "Mesačné účty",
        MyWorksheetTitle: "Pracovný list",
        NameWS: "Meno",
        NoDataWorksheetNull: "Žiadne dáta na zobrazenie, skontrolujte prístup na svoju osobu.",
        PassesWS: "Priechody",
        Period: "Obdobie",
        ShiftsFund: "Fond podľa zmien",
        Sign: "Podpísať",
        Signature: "Podpísal",
        SignTitle: "Podpísanie pracovného listu",
        SignatureNeededWarning: "Podpíšte si svoj pracovný list!",
        State_Approved: "Schválený",
        State_Confirmed: "Potvrdený",
        State_Open: "Otvorený",
        State_SignedElectronically: "Podpísaný elektronicky",
        State_SignedHandwritten: "Podpísaný ručne",
        TransferPast: "Prevod z minula",
        TransferNext: "Prevod do ďalšieho obdobia",
        Worked: "Odpr.",
        Attachments: "Prílohy",
        NoAttachments: "Nie sú priložené žiadne prílohy.",
        TakePicture: "Vyfotit",
        AttachmentName: "Názov",
        AttachmentNote: "Poznámka",
        NewAttachemnt: "Nová priloha",
        Upload: "Nahrať",
    },
    worksheetStates: {
        TitleWSStates: "Stavy PL",
        AllChosen: "Všetky",
        OnlyApprovedWS: "Iba schválené",
        OnlyCreated: "Iba otvorené",
        OnlyConfirmed: "Iba potvrdené",
        OnlySigned: "Iba podpísané elektronicky",
        OnlySignedHandwritten: "Iba podpísané ručne",
        OnlyUnSigned: "Iba nepodpísané",
    },
    planStates: {
        Application: "Aplikácia",
        Checked: "Oboznámený elektronicky",
        CheckedHand: "Oboznámený ručne",
        Unchecked: "Neoboznámený",
        Checkedby: "Oboznámil",
        ChangeFrom: "Zmena od",
        ChangedBy: "Odsúhlasil",
        DateFrom: "Obdobie od",
        DateTo: "Obdobie do",
        DetailCheck: "Detail oboznámenia",
        DetailRequest: "Detail požiadavky na zmenu",
        NotPassed: "Nepostúpený",
        OnlyNotPassed: "Iba nepostúpené",
        OnlyChecked: "Iba oboznámené elektronicky",
        OnlyCheckedHand: "Iba oboznámené ručne",
        OnlyUnchecked: "Iba neoboznámené",
        OnlyApprovedPS: "Iba odsúhlasené elektronicky",
        OnlyApprovedPSHand: "Iba odsúhlasené ručne",
        OnlyRejected: "Iba zamietnuté elektronicky",
        OnlyRejectedHand: "Iba zamietnuté ručne",
        Waiting: "Čakajúce na odsúhlasenie",
        OnlyWaiting: "Iba čakajúce na odsúhlasenie",
        ApprovedState: "Odsúhlasený elektronicky",
        ApprovedStateHand: "Odsúhlasený ručne",
        PreferedKind: "Preferovaný spôsob",
        TitlePS: "Stavy plánov",
    },
    plan: {
        WasSubmittedBy: "Odovzdal na oboznámenie",
        PlanWasChangedBy: "Plán zmenil",
        Legend: "Legenda",
        DetailPlan: "Detail",
        RequestDetail: "Detail požiadavky",
        PlanChange: "Zmena plánu",
        ApprovalOfPlanChanges: "Odsúhlasenie zmien plánu",
        AcquaintanceWithPlan: "Zoznámenie s plánom",
        Msg_ConfirmAcquaintWithYourPlanPlease: "Potvrďte prosím zoznámenie so svojím plánom.",
        Msg_ApprovePlanChanges: "Odsúhlaste zmeny v pláne.",
        PlanName: "Plán",
        N3Days: "3-Dni",
        Week: "Týždeň",
        Msg_TooMuchPersons: 'Stredisko "{0}" zahŕňa viac osôb ako je povolené načítať ({1}).',
        OutsideOfWorkplace: "Mimo pracoviska",
        Recalculate: "Prepočítať plány",
        NotPublished: "Pre tento mesiac nebol predložen plán na zoznámenie.",
        ContainsUnpublished: "Tento mesiac obsahuje obdobie, pre ktoré nebol predložen plán na zoznámenie.",
    },
    presence: {
        DetailPresence: "Detail prítomnosti",
        AllPersons: "Všetky osoby",
        Present: "Prítomný",
        OnlyInconsistent: "Len s nezrovnalosťami prítomnosti",
        OnlyNotPresent: "Len neprítomné osoby",
        OnlyPresent: "Len prítomné osoby",
        OnlyWithShift: "Len s naplánovanou zmenou",
        Absent: "Neprítomný",
        Unknown: "Nezistený",
        LastPassName: "Posledný priechod",
        LastPassTime: "Čas posledného priechodu",
        MaxCount: 'Stredisko "{0}" zahŕňa viac osôb ako je povolené načítať (1000).',
        ShiftName: "Zmena",
        TerminalPres: "Terminál",
        TitlePresence: "Prítomnosť",
    },
    overtime: {
        Title: "Nadčasy",
        DialogTitle: "Nadčas",
        Day: "Deň",
        Note: "Poznámka",
        From: "Začíatok",
        To: "Koniec",
        Account: "Účet",
    },
    debug: {
        ErrorsMany: "Chyby",
        ShowError: "Zobraziť chyby",
        Msg_ClickForCopyDetailsIntoClipboard: "Kliknite pre skopírovanie podrobností do schránky.",
        Msg_DetailsSuccessfullyCopiedIntoClipboard: "Podrobnosti úspešne skopírované do schránky.",
    },
    centerPicker: {
        NotSelected: "Nevybrané",
        NoSubcenters: "Žiadne podstrediská",
        IncludingSubcenters: "Vrátane podstredísk",
    },
    responses: {
        over_limit: "Prekročený zostatok dovolenky.",
        period_locked: "Pracovný list pre toto obdobie je už potvrdený.",
        attachment_upload_success: "Príloha bola nahraná.",
        attachment_upload_error: "Nahrávanie prílohy sa nepodarilo.",
        date_reserved_unava: "Vybrané obdobie už obsahuje nedostupnosť.",
        license_error: "Počet užívateľov SelfService prekračuje počet licencií.",
        free_shift_assigned: "Zmena bola pridelenáa",
        free_shift_unavailable: "Zmena už nie je k dispozícii",
        err_labor_law: "Zmenu nemožno prideliť z dôvodu porušenia zákonníka práce.",
        pass_inserted: "Bol vložený priechod:",
        pass_not_inserted: "Priechod nebol vložený! Skontrolujte pripojenie.",
    },
    actions: {
        DepartmentPlanSelfServiceApp_RunApp: "Plán oddelenia",
        MyPlanSelfServiceApp_RunApp: "Môj plán",
        MyRequestsSelfServiceApp_RunApp: "Moje požiadavky",
        MyUnavailabilitiesSelfServiceApp_RunApp: "Moja nedostupnosť",
        MyWSSelfServiceApp_RunApp: "Môj pracovný list",
        OvertimeSelfServiceApp_RunApp: "Moje nadčasy",
        PlanStatesSelfServiceApp_RunApp: "Stavy plánov",
        PresenceSelfServiceApp_RunApp: "Prítomnosť",
        ReportsSelfServiceApp_RunApp: "Reporty",
        RequestsApprovalSelfServiceApp_RunApp: "Požiadavky na schválenie",
        TerminalSelfServiceApp_RunApp: "Terminál",
        UnavasApprovalSelfServiceApp_RunApp: "Schvaľovanie nedostupností",
        WorksheetStatesSelfServiceApp_RunApp: "Stavy pracovných listov",
    },
    substitutions: {
        Person: "Osoba:",
        From: "Od:",
        To: "Do:",
        Substitution: "Zastupovanie",
        Add: "Pridať",
        Edit: "Upraviť",
        Undo: "Vrátiť",
    },
    freeShifts: {
        Days: ["Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota", "Nedeľa", "Sviatok"],
        CentersConfig: "Preferované strediská",
        TimesConfig: "Preferované časy",
        NotifConfig: "Notifikácie",
        Selection: "Výber zmeny",
        Date: "Dátum",
        Center: "Stredisko",
        Start: "Začiatok",
        End: "Koniec",
        Category: "Kategória",
        Categories: "Kategórie",
        ErrorMin: "Zmenu je možné predĺžiť maximálne o $0$ minút. (V súčasnosti sa predlžuje o $1$ minút.)",
        ErrorMax: "Zmenu je možné skrátiť maximálne o $0$ minút. (V súčasnosti sa skracuje o $1$ minút.)",
        CenterSelection: "Výber strediska",
        All: "Všetky",
        Preferred: "Preferované",
        Others: "Ostatné",
        CancelShift: "Zrušiť zmenu",
    },
};
