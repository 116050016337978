import React, { useState, FormEvent, useEffect } from "react";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import classes from "./LogInForm.module.scss";
import { fetchToken } from "../../redux/actions/user";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { deleteSavedUser } from "../../redux/actions/localStorage/deleteSavedUser";

const BASE_KEY = "ANeT/SelfService/login";
const REMEMBERED_USERNAME_KEY = BASE_KEY + "/user/username";
const REMEMBERED_PASSWORD_KEY = BASE_KEY + "/user/password";
const REMEMBERED_CHECKBOX_KEY = BASE_KEY + "/remember";

function LogInForm(props: { onCodeClear: () => void }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(
        JSON.parse(localStorage.getItem(REMEMBERED_CHECKBOX_KEY) || "false") || false
    );
    const multiTenant = useAppSelector(s => s.app.multiTenant);

    useEffect(() => {
        const rememberedUsername = localStorage.getItem(REMEMBERED_USERNAME_KEY);
        if (rememberedUsername !== "" && rememberedUsername != null) {
            setUsername(rememberedUsername);
        }
        const rememberedPassword = localStorage.getItem(REMEMBERED_PASSWORD_KEY);
        if (rememberedPassword !== "" && rememberedPassword != null) {
            setPassword(rememberedPassword);
        }
    }, []);

    const dispatch = useAppDispatch();

    function handleFormSubmit(e: FormEvent) {
        e.preventDefault();
        if (remember) {
            localStorage.setItem(REMEMBERED_USERNAME_KEY, username);
            localStorage.setItem(REMEMBERED_PASSWORD_KEY, password);
        } else {
            deleteSavedUser();
        }
        localStorage.setItem(REMEMBERED_CHECKBOX_KEY, JSON.stringify(remember));

        dispatch(
            fetchToken({
                credentials: {
                    username: username,
                    password: password,
                },
            })
        );
    }

    const strings = useStrings();
    const isAuthenticating = useAppSelector(s => s.session.user.isAuthenticating);
    const handleCheck = (event: CheckboxChangeEvent) => setRemember(event.value);
    return (
        <form onSubmit={handleFormSubmit}>
            <fieldset className={classes.fieldset}>
                <Input
                    name="username"
                    label={strings.login.Username}
                    onChange={event => setUsername(event.value)}
                    value={username}
                    required={true}
                    disabled={isAuthenticating}
                    placeholder=" "
                    autoComplete="username"
                />
                <Input
                    name="password"
                    type="password"
                    label={strings.login.Password}
                    onChange={event => setPassword(event.value)}
                    value={password}
                    required={false}
                    disabled={isAuthenticating}
                    placeholder=" "
                    autoComplete="current-password"
                />
                <Checkbox key={0} id={"rememberCheck"} value={remember} onChange={handleCheck}>
                    <label htmlFor={"rememberCheck"} className={"k-checkbox-label"}>
                        {strings.login.Remember}
                    </label>
                </Checkbox>
            </fieldset>
            <Button
                type="submit"
                className="w-100"
                primary={true}
                disabled={isAuthenticating}
                icon={isAuthenticating ? "loading" : undefined}
            >
                {strings.login.Login}
            </Button>
            {multiTenant && (
                <div className={classes.changeCode} onClick={props.onCodeClear}>
                    {strings.login.CustomerCodeChange}
                </div>
            )}
        </form>
    );
}

export default LogInForm;
