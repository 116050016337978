import React from "react";
import { PageLayout } from "../components/PageLayout/PageLayout";
import { NavBar } from "../components/NavBar/NavBar";
import { useAppSelector } from "../hooks/useAppSelector";

export default function NotFoundPage() {
    const message = useAppSelector(s => s.localization.strings.app.PageNotFound);
    const title = useAppSelector(s => s.localization.strings.app.PageNotFoundTitle);

    return (
        <PageLayout header={<NavBar canNavigateRoot label={title} />}>
            <div className="page-container">
                <p style={{ textAlign: "center" }}>{message}</p>
            </div>
        </PageLayout>
    );
}
