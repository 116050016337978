import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { editSubstitution, toggleSubstitution, fetchSubstitutions, createSubstitution } from "../actions/substitutions";

export interface ISubstitutuinState {
    isFetching: boolean;
    actions: SubstitutionAction[];
    people: SubstitutionPerson[];
    substitutions: Substitution[];
    error?: SerializedError;
}

export interface Substitution {
    id: number;
    name: string;
    start: string;
    end: string;
    ixsref: string;
    new?: boolean;
    deleted?: boolean;
    edited?: boolean;
    actions: string[];
}

export interface SubstitutionPerson {
    name: string;
    ixsref: string;
}

export interface SubstitutionAction {
    id: string;
    desc: string;
}

export const substitutionReducer = createReducer<ISubstitutuinState>(
    {
        isFetching: false,
        actions: [],
        people: [],
        substitutions: [],
    },
    builder => {
        builder
            .addCase(fetchSubstitutions.pending, state => ({
                ...state,
                isFetching: true,
                actions: [],
                substitutions: [],
                people: [],
                error: undefined,
            }))
            .addCase(fetchSubstitutions.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                actions: action.payload.actions ?? [],
                people: action.payload.people ?? [],
                substitutions: action.payload.substitutions ?? [],
                error: undefined,
            }))
            .addCase(fetchSubstitutions.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                error: action.error,
            }))
            .addCase(createSubstitution, (state, action) => ({
                ...state,
                substitutions: [
                    ...state.substitutions,
                    {
                        id: Math.min(...state.substitutions.map(m => m.id), 0) - 1,
                        ixsref: action.payload.ixsref,
                        start: action.payload.start,
                        end: action.payload.end,
                        name: state.people.find(f => f.ixsref === action.payload.ixsref)?.name ?? "",
                        actions: action.payload.actions,
                        new: true,
                    },
                ],
            }))
            .addCase(editSubstitution, (state, action) => ({
                ...state,
                substitutions: state.substitutions.map(m =>
                    m.id === action.payload.id
                        ? {
                              ...m,
                              ixsref: action.payload.ixsref,
                              start: action.payload.start,
                              end: action.payload.end,
                              name: state.people.find(f => f.ixsref === action.payload.ixsref)?.name ?? "",
                              actions: action.payload.actions,
                              edited: true,
                          }
                        : m
                ),
            }))
            .addCase(toggleSubstitution, (state, action) => ({
                ...state,
                substitutions: state.substitutions.map(m =>
                    m.id === action.payload ? { ...m, deleted: !m.deleted } : m
                ),
            }));
    }
);
