import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ReportItem } from "../../model/ReportItem";
import { fetchReportData, fetchReportsItems } from "../actions/reports";

export interface IReportsState {
    isFetching?: boolean;
    data?: Uint8Array;
    error?: unknown;
}

export const reportsReducer = createReducer<IReportsState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchReportData.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchReportData.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: action.payload?.$value,
            }))
            .addCase(fetchReportData.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: undefined,
                error: action.payload,
            }));
    }
);

export interface IReportItemsState {
    isFetching?: boolean;
    data?: ReportItem[];
    error?: unknown;
}

export const reportItemsReducer = createReducer<IReportItemsState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchReportsItems.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchReportsItems.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: action.payload,
            }))
            .addCase(fetchReportsItems.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                error: action.payload,
            }));
    }
);
